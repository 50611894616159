<template>
  <div>
    <div class="wrapper">
      <Sidebar> </Sidebar>
      <Content>
        <TopNav></TopNav>
        <router-view></router-view>
      </Content>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/main/Sidebar";
import Content from "../components/main/Content";
import TopNav from "../components/main/TopNav";
import LoginRequired from "../mixins/LoginRequired";

export default {
  mixins: [LoginRequired],
  components: {
    Sidebar: Sidebar,
    Content: Content,
    TopNav: TopNav,
  },
  methods: {
    testSpeed: function () {
      this.$store.commit("switchSpeed");
      setTimeout(() => this.testSpeed(), 1000);
    },
    /**
     * Updates the state with values from the backend for every
     * specific module.
     *
     * Updates:
     * - Evac Manager Status
     * - POC Time
     * - Smart Safety System
     * - Fire Sensors
     * - Passenger Issues
     */
    updateState: function (axiosAuth) {
      let evac_manager_promise = axiosAuth
        .get("/evac_coordinator/")
        .then((response) => {
          if (
            this.$store.state.incident.evacuation_level.toString() !=
            response.data.level
          ) {
            this.$store.commit("setEvacManagerLevel", response.data.level);
          }
        });
      let poc_time_promise = axiosAuth.get("/poc_time/").then((response) => {
        if (
          this.$store.state.incident.poc_time != response.data.time ||
          this.$store.state.incident.poc_control_enabled !=
            response.data.enabled
        ) {
          this.$store.commit("setPoCTime", response.data.time);
          this.$store.commit("setPoCEnabled", response.data.enabled);
        }
      });

      let sss_promise = axiosAuth.get("/sss/").then((response) => {
        this.$store.commit("setSSSSnap", response.data);
      });

      let passenger_issues_promise = axiosAuth
        .get("/pameas-notification/")
        .then((response) => {
          let passenger_issues = response.data;
          // debugger;
          this.$store.commit("setPassengerIssuesData", passenger_issues);
        });

      let sensors_promise = axiosAuth.get("/fire-sensor/").then((response) => {
        let sensors = {};
        for (const s of response.data) {
          sensors[s.slug] = {
            label: s.label,
            triggered: s.triggered,
            geofence_affected: s.geofence_affected
            // value: Number.parseFloat(s.value).toFixed(2),
            // max: Number.parseFloat(s.max_allowed).toFixed(2),
          };
        }
        this.$store.commit("setSensorData", sensors);
      });

      let srap_promise = axiosAuth.get("/srap/latest/").then((response) => {
        this.$store.commit("setSRAP", response.data);
      });
      Promise.allSettled([
        evac_manager_promise,
        poc_time_promise,
        sss_promise,
        sensors_promise,
        passenger_issues_promise,
        srap_promise,
      ]).then(() => {
        setTimeout(() => this.updateState(axiosAuth), 1000);
      });
    },
  },
  created: function () {
    var axiosAuth = this.$store.getters.authenticatedAxios;
    this.updateState(axiosAuth);
    this.testSpeed();
  },
  data: function () {},

  // beforeRouteEnter (to, from, next) {
  //     next(vm => {
  //         const store = vm.$store;
  //         const isGuest = to.meta.guest;
  //         debugger;
  //         if (!isGuest && store.state.auth.isLoggedIn())
  //             // If the route allows guest and the user is logged in, go to next.
  //             next();
  //         else if (isGuest && store.state.auth.isLoggedIn())
  //             // If the route is a guest route and the user is logged in, redirect to home.
  //             next("/");
  //         else
  //             // Else redirect to login.
  //             next("/login");
  //     })
  // }
};
</script>