export default {
  state: {
    sss_snap: {
      incident_type: null,
      raised_timestamp: null,
    },
    spb_legacy: {
      speed: 14,
      location: "From Smart Safety System",
      name: "Hellenic Spirit, Greece",
      stigma: "From Shipboard Legacy Systems",
      stability_toolkit: "From Stability Toolkit Inclination",
    },
    srap: {
      effectiveness_of_mitigation_measures:
        "From SRAP Effectiveness of mitigation measures on site",
      spreading: "From SRAP Spreading",
      hull_status: "From SRAP Hull Status",
      stability: "From SRAP Stability Likelihood",
      structural_integrity: "From SRAP Stractural Integrity",
      // ability_to_navigate: "From SRAP Ability To Navigate",
      passengers_proximity_to_hazards:
        "From SRAP Passengers Proximity To Hazards",
      vessel_status: "From SRAP Vessel Status",
      critical_system_status: "From SRAP Critical Systems Status",
      status_of_passive_contaimnent: "From SRAP Status Of Passive Containment",
      ability_to_communicate: "From SRAP Ability To Communicate",
      situation_assessment: "Do not Sound GA",
      abandon_vessel: "Do not abandon",
      risk_of_delay: {
        "Z1D1": 'Low',
        "Z2D1": 'Medium',
        "Z1D2": 'Low',
        "Z2D2": 'High',
        "Z1D3": 'Zero',
        "Z2D3": 'Medium',
        "Z1D4": 'Low',
        "Z2D4": 'High'
      },
    },
    shipStabilityToolkit: {
      roll: null,
      sway: null,
      surge: null,
      pitch: null,
      heave: null,
      yaw: null,
    },
    sensors: null,
    passenger_issues: null,
    ignoredSensors: [],
    voyageStatus: 0,
    dss_suggestions: null,
    suggestionDataMap: {
      GRD_001: {
        target: ["Master"],
      },
      GRD_002: {
        target: ["Master"],
        info: [["Risk", "this.$store.getters.getHullStatus"]],
      },
      GRD_003: {
        target: ["Master"],
      },
      GRD_004: {
        target: ["Master"],
        info: [
          ["Risk", "this.$store.getters.getStabilityLikelihood"],
          ["Pitch", "(this.$store.getters.getStabilityToolkit).pitch"],
          ["Roll", "(this.$store.getters.getStabilityToolkit).roll"],
        ],
      },
      GRD_005: {
        target: ["Master"],
        info: []
        // info: [["Risk", "this.$store.getters.getIncidentSeverity"]],
      },
      GRD_006: {
        target: ["Master"],
        info: [["Risk", "this.$store.getters.getStructuralIntegrity"]],
      },
      GRD_007: {
        target: ["Master"],
        info: [
          // [
          //   "Risk referred to the crew only, and not for population of the area",
          //   "this.$store.getters.getIncidentSeverity",
          // ],
        ],
      },
      GRD_008: {
        target: ["Master"],
      },
      GRD_009: {
        target: ["Master"],
        info: [["Risk", "this.$store.getters.getVesselStatus"]],
      },
      GRD_010: {
        target: ["Master"],
      },
      GRD_011: {
        target: ["Master"],
      },
      GRD_012: {
        target: ["Master"],
      },
      GRD_013: {
        target: ["Master"],
      },
      GRD_014: {
        target: ["Master"],
      },
      GRD_015: {
        target: ["Master"],
      },
      GRD_016: {
        target: ["Master"],
      },
      GRD_017: {
        target: ["Master"],
      },
      GRD_018: {
        target: ["Master"],
      },
      GRD_019: {
        target: ["Master"],
        info: [["Risk", "this.$store.getters.getStructuralIntegrity"]],
      },
      GRD_020: {
        target: ["Master"],
        info: [
          //["Risk", "this.$store.getters.getAbilityToNavigate"]
        ],
      },
      GRD_021: {
        target: ["Master"],
      },
      GRD_022: {
        target: ["Master"],
        info: [["Risk", "this.$store.getters.getCriticalSystemsStatus"]],
      },
      GRD_023: {
        target: ["Master"],
      },
      GRD_024: {
        target: ["Master"],
      },
      GRD_025: {
        target: ["Master"],
      },
      GRD_026: {
        target: ["Master"],
      },
      GRD_027: {
        target: ["Master"],
      },
      GRD_028: {
        target: ["Master"],
      },
      GRD_029: {
        target: ["Master"],
      },
      GRD_030: {
        target: ["Master"],
      },
      GRD_031: {
        target: ["Master"],
      },
      GRD_032: {
        target: ["Master"],
      },
      GRD_033: {
        target: ["Master"],
      },
      GRD_034: {
        target: ["Master"],
      },
      GRD_035: {
        target: ["Master"],
      },
      GRD_036: {
        target: ["Master"],
      },
      GRD_037: {
        target: ["Master"],
      },
      GRD_038: {
        target: ["Master"],
      },
      GRD_039: {
        target: ["Master"],
      },
      GRD_040: {
        target: ["Master"],
      },
      GRD_041: {
        target: ["Master"],
      },
      GRD_042: {
        target: ["Master"],
      },
      GRD_043: {
        target: ["Master"],
      },
      GRD_044: {
        target: ["Master"],
      },
      GRD_045: {
        target: ["Master"],
      },
      GRD_046: {
        target: ["Master"],
      },
      GRD_047: {
        target: ["Master"],
      },
      GRD_048: {
        target: ["Master"],
      },
      GRD_049: {
        target: ["Master"],
      },
      GRD_050: {
        target: ["Master"],
      },
      GRD_051: {
        target: ["Master"],
      },
      GRD_052: {
        target: ["Master"],
      },
      GRD_053: {
        target: ["Master"],
      },
      GRD_054: {
        target: ["Master"],
      },
      GRD_055: {
        target: ["Master"],
      },
      GRD_056: {
        target: ["Master"],
      },
      GRD_057: {
        target: ["Master"],
      },
      GRD_058: {
        target: ["Master"],
      },
      GRD_059: {
        target: ["Master"],
      },
      GRD_060: {
        target: ["Master"],
      },
      GRD_061: {
        target: ["Master"],
      },
      GRD_062: {
        target: ["Master"],
      },
      GRD_063: {
        target: ["Master"],
        info: [["Risk", "this.$store.getters.getHullStatus"]],
      },
      GRD_064: {
        target: ["Master"],
      },
      GRD_065: {
        target: ["Master"],
      },
      GRD_066: {
        target: ["Master"],
      },
      GRD_067: {
        target: ["Master"],
      },
      GRD_068: {
        target: ["Master"],
      },
      GRD_069: {
        target: ["Master"],
      },
      GRD_070: {
        target: ["Master"],
      },
      GRD_071: {
        target: ["Master"],
      },
      GRD_072: {
        target: ["Master"],
      },
      GRD_073: {
        target: ["Master"],
      },
      GRD_074: {
        target: ["Master"],
      },
      GRD_075: {
        target: ["Master"],
      },
      GRD_076: {
        target: ["Master"],
      },
      GRD_077: {
        target: ["Master"],
      },
      GRD_078: {
        target: ["Master"],
      },
      GRD_079: {
        target: ["Master"],
      },
      GRD_080: {
        target: ["Master"],
      },
      GRD_081: {
        target: ["Master"],
      },
      GRD_082: {
        target: ["Master"],
      },
      GRD_083: {
        target: ["Master"],
      },
      GRD_084: {
        target: ["Master"],
      },
      GRD_085: {
        target: ["Master"],
      },
      GRD_086: {
        target: ["Master"],
      },
      GRD_087: {
        target: ["Master"],
      },
      GRD_088: {
        target: ["Master"],
      },
      GRD_089: {
        target: ["Master"],
      },
      GRD_090: {
        target: ["Master"],
      },
      GRD_091: {
        target: ["Master"],
      },
      GRD_092: {
        target: ["Master"],
      },
      GRD_093: {
        target: ["Master"],
      },
      GRD_094: {
        target: ["Master"],
      },
      GRD_095: {
        target: ["Master"],
      },
      GRD_096: {
        target: ["Master"],
      },
      GRD_097: {
        target: ["Master"],
      },
      GRD_098: {
        target: ["Master"],
      },
      GRD_099: {
        target: ["Master"],
        info: [
          ["Risk", "this.$store.getters.getStructuralIntegrity"],
          ["Risk", "this.$store.getters.getStabilityLikelihood"],
          ["Risk", "this.$store.getters.getStabilityToolkit"],
        ],
      },
      GRD_100: {
        target: ["Master"],
      },
      GRD_101: {
        target: ["Master"],
      },
      GRD_102: {
        target: ["Master"],
      },
      GRD_103: {
        target: ["Master"],
      },
      GRD_104: {
        target: ["Master"],
      },
      GRD_105: {
        target: ["Master"],
      },
      GRD_106: {
        target: ["Master"],
      },
      GRD_107: {
        target: ["Master"],
      },
      GRD_108: {
        target: ["Master"],
        info: [
          ["Risk", "this.$store.getters.getStructuralIntegrity"],
          ["Risk", "this.$store.getters.getStabilityLikelihood"],
          ["Risk", "this.$store.getters.getStabilityToolkit"],
        ],
      },
      GRD_109: {
        target: ["Master"],
      },
      GRD_110: {
        target: ["Master"],
      },
      GRD_111: {
        target: ["Master"],
      },
      GRD_112: {
        target: ["Master"],
      },
      GRD_113: {
        target: ["Master"],
      },
      GRD_114: {
        target: ["Master"],
        info: [
          ["Risk", "this.$store.getters.getStructuralIntegrity"],
          ["Risk", "this.$store.getters.getStabilityLikelihood"],
          ["Risk", "this.$store.getters.getStabilityToolkit"],
        ],
      },
      GRD_115: {
        target: ["Master"],
      },
      GRD_116: {
        target: ["Master"],
      },
      GRD_117: {
        target: ["Master"],
      },
      GRD_118: {
        target: ["Master"],
      },
      GRD_119: {
        target: ["Master"],
      },
      FRE_001: {
        target: ["Bridge Officer", "Engine Officer"],
      },
      FRE_002: {
        info: [["Risk", "this.$store.getters.getAbilityToCommunicate"]],
        target: ["Bridge Officer", "Engine Officer"],
      },
      FRE_003: {
        target: ["Bridge Officer", "Engine Officer"],
      },
      FRE_004: {
        target: ["Master", "First Mate"],
      },
      FRE_005: {
        target: ["First Engineer"],
      },
      FRE_006: {
        target: ["First Engineer"],
      },
      FRE_007: {
        target: ["First Engineer"],
      },
      FRE_008: {
        target: ["Bridge Officer"],
      },
      FRE_009: {
        target: ["Electrician"],
      },
      FRE_010: {
        info: [["Risk", "this.$store.getters.getEffectiveness"]],
        target: ["Master"],
      },
      FRE_011: {
        target: ["Master", "Radio-broadcast Officer"],
      },
      FRE_012: {
        info: [["Risk", "this.$store.getters.getPassengersProximityToHazards"]],
        target: ["Master"],
      },
      FRE_013: {
        target: ["First Mate"],
      },
      FRE_014: {
        info: [
          // ["Risk", "this.$store.getters.getIncidentSeverity"],
          ["Risk", "this.$store.getters.getSpreading"],
        ],
        target: ["Master"],
      },
      FRE_015: {
        target: ["Master", "First Engineer", "First Mate"],
      },
      FRE_016: {
        target: ["Master", "First Engineer", "First Mate"],
      },
      FRE_017: {
        target: ["First Engineer", "First Mate"],
      },
      FRE_018: {
        target: ["Bridge Officer"],
      },
      FRE_019: {
        target: ["Master"],
      },
      FRE_020: {
        info: [["Risk", "this.$store.getters.getEffectiveness"]],
        target: ["First Mate", "OAK"],
      },
      FRE_021: {
        target: ["First Mate", "OAK"],
      },
      FRE_022: {
        target: ["First Mate", "OAK", "Medical Team"],
      },
      FRE_023: {
        target: ["Master"],
      },
      FRE_024: {
        target: ["Bridge Officer"],
      },
      FRE_025: {
        target: ["Master", "First Engineer", "First Mate"],
      },
      FRE_026: {
        target: ["Master", "First Mate"],
      },
      FRE_027: {
        info: [["Risk", "this.$store.getters.getAbilityToCommunicate"]],
        target: ["Master", "First Mate"],
      },
      FRE_028: {
        target: ["Master", "First Mate"],
      },
      FRE_029: {
        info: [["Risk", "this.$store.getters.getPassengersProximityToHazards"]],
        target: ["Master"],
      },
      FRE_030: {
        target: ["Master", "Bridge Officer"],
      },
      FRE_031: {
        target: ["Master"],
      },
      FRE_032: {
        target: ["Master", "Radio-broadcast Officer"],
      },
      FRE_033: {
        target: ["Responsible Crew"],
      },
      FRE_034: {
        target: ["First Mate", "Life-saving Equipment Team"],
      },
      FRE_035: {
        target: ["Master"],
      },
      FRE_036: {
        target: ["Master"],
      },
      FRE_037: {
        target: ["Master"],
      },
      FRE_038: {
        target: ["Master", "Responsible Officer"],
      },
      FRE_039: {
        target: ["Bridge Officer"],
      },
      FRE_040: {
        info: [["Point", "this.$store.getters.getLocation"]],
        target: ["Patrol Sailor ", "Responsible Crew"],
      },
      FRE_041: {
        info: [["Risk", "this.$store.getters.getAbilityToCommunicate"]],
        target: ["Bridge Officer"],
      },
      FRE_042: {
        info: [["Risk", "this.$store.getters.getEffectiveness"]],
        target: ["Master", "Bridge Officer"],
      },
      FRE_043: {
        target: ["Patrol Sailor ", "Responsible Crew"],
      },
      FRE_044: {
        target: ["Master"],
      },
      FRE_045: {
        target: ["Bridge Officer", "Responsible Crew"],
      },
      FRE_046: {
        target: ["Electrician"],
      },
      FRE_047: {
        target: ["First Mate", "OAK"],
      },
      FRE_048: {
        target: ["Bridge Officer", "Engine Officer"],
      },
      FRE_049: {
        target: ["Master"],
      },
      FRE_050: {
        info: [["Risk", "this.$store.getters.getPassengersProximityToHazards"]],
        target: ["Master"],
      },
      FRE_051: {
        info: [["Risk", "this.$store.getters.getEffectiveness"]],
        target: ["First Mate", "OAK"],
      },
      FRE_052: {
        target: ["First Mate", "OAK"],
      },
      FRE_053: {
        target: ["First Mate", "OAK", "Medical Team"],
      },
      FRE_054: {
        target: ["Master"],
      },
      FRE_055: {
        target: ["Bridge Officer"],
      },
      FRE_056: {
        target: ["Master"],
      },
      FRE_057: {
        target: ["Master", "First Engineer", "First Mate"],
      },
      FRE_058: {
        target: ["First Mate", "OAK"],
      },
      FRE_059: {
        info: [["Risk", "this.$store.getters.getAbilityToCommunicate"]],
        target: ["Master", "First Mate"],
      },
      FRE_060: {
        target: ["Master", "First Mate"],
      },
      FRE_061: {
        info: [["Risk", "this.$store.getters.getPassengersProximityToHazards"]],
        target: ["Master"],
      },
      FRE_062: {
        target: ["Master", "Bridge Officer"],
      },
      FRE_063: {
        target: ["Master"],
      },
      FRE_064: {
        target: ["Master", "Radio-broadcast Officer"],
      },
      FRE_065: {
        target: ["Responsible Crew"],
      },
      FRE_066: {
        target: ["First Mate", "Life-saving Equipment Team"],
      },
      FRE_067: {
        target: ["Master"],
      },
      FRE_068: {
        target: ["Master"],
      },
      FRE_069: {
        target: ["Master"],
      },
      FRE_070: {
        target: ["Master", "Responsible Officer"],
      },
      FRE_071: {
        target: ["Master", "Responsible Officer"],
      },
      FRE_072: {
        target: ["Patrol Sailor", "Kitchen Crew"],
      },
      FRE_073: {
        info: [["Risk", "this.$store.getters.getAbilityToCommunicate"]],
        target: ["Patrol Sailor", "Bridge Officer"],
      },
      FRE_074: {
        target: ["Patrol Sailor", "Kitchen Crew"],
      },
      FRE_075: {
        target: ["Patrol Sailor", "Kitchen Crew"],
      },
      FRE_076: {
        target: ["Patrol Sailor", "Kitchen Crew"],
      },
      FRE_077: {
        target: ["Master", "First Mate"],
      },
      FRE_078: {
        target: ["Master", "First Mate"],
      },
      FRE_079: {
        info: [["Risk", "this.$store.getters.getEffectiveness"]],
        target: ["Master"],
      },
      FRE_080: {
        target: ["Master"],
      },
      FRE_081: {
        info: [["Risk", "this.$store.getters.getPassengersProximityToHazards"]],
        target: ["Master"],
      },
      FRE_082: {
        info: [["Risk", "this.$store.getters.getEffectiveness"]],
        target: ["First Mate", "OAK"],
      },
      FRE_083: {
        target: ["First Mate", "OAK"],
      },
      FRE_084: {
        target: ["First Mate", "OAK", "Medical Team"],
      },
      FRE_085: {
        target: ["Master"],
      },
      FRE_086: {
        target: ["Bridge Officer"],
      },
      FRE_087: {
        target: ["Master"],
      },
      FRE_088: {
        target: ["Master", "First Engineer", "First Mate"],
      },
      FRE_089: {
        target: ["First Mate", "OAK"],
      },
      FRE_090: {
        target: ["Master", "Responsible Officer"],
      },
      FRE_091: {
        info: [["Risk", "this.$store.getters.getAbilityToCommunicate"]],
        target: ["Master", "First Mate"],
      },
      FRE_092: {
        target: ["Master", "First Mate"],
      },
      FRE_093: {
        info: [["Risk", "this.$store.getters.getPassengersProximityToHazards"]],
        target: ["Master"],
      },
      FRE_094: {
        target: ["Master", "Bridge Officer"],
      },
      FRE_095: {
        target: ["Master"],
      },
      FRE_096: {
        target: ["Master", "Radio-broadcast Officer"],
      },
      FRE_097: {
        target: ["Responsible Crew"],
      },
      FRE_098: {
        target: ["First Mate", "Life-saving Equipment Team"],
      },
      FRE_099: {
        target: ["Master"],
      },
      FRE_100: {
        target: ["Master"],
      },
      FRE_101: {
        target: ["Master"],
      },
      FRE_102: {
        target: ["Master"],
      },
      FRE_103: {
        target: ["Bridge Officer"],
      },
      FRE_104: {
        // info: [["Point", "this.$store.getters.getLocation"]],
        target: ["Patrol Sailor"],
      },
      FRE_105: {
        info: [["Risk", "this.$store.getters.getAbilityToCommunicate"]],
        target: ["Bridge Officer"],
      },
      FRE_106: {
        target: ["Bridge Officer", "Engine Officer"],
      },
      FRE_107: {
        target: ["Bridge Officer", "Electrician"],
      },
      FRE_108: {
        target: ["First Mate"],
      },
      FRE_109: {
        info: [["Risk", "this.$store.getters.getEffectiveness"]],
        target: ["Master"],
      },
      FRE_110: {
        target: ["Master", "Radio-broadcast Officer"],
      },
      FRE_111: {
        info: [["Risk", "this.$store.getters.getPassengersProximityToHazards"]],
        target: ["Master"],
      },
      FRE_112: {
        target: ["First Mate", "OAK"],
      },
      FRE_113: {
        target: ["First Mate"],
      },
      FRE_114: {
        target: ["First Engineer"],
      },
      FRE_115: {
        info: [["Risk", "this.$store.getters.getEffectiveness"]],
        target: ["First Mate", "OAK"],
      },
      FRE_116: {
        target: ["First Mate", "OAK"],
      },
      FRE_117: {
        target: ["First Mate", "OAK"],
      },
      FRE_118: {
        target: ["Master"],
      },
      FRE_119: {
        target: ["Bridge Officer"],
      },
      FRE_120: {
        target: ["Master"],
      },
      FRE_121: {
        target: ["Master", "First Engineer", "First Mate"],
      },
      FRE_122: {
        target: ["Master", "First Mate"],
      },
      FRE_123: {
        info: [["Risk", "this.$store.getters.getAbilityToCommunicate"]],
        target: ["Master", "First Mate"],
      },
      FRE_124: {
        target: ["Master", "First Mate"],
      },
      FRE_125: {
        info: [["Risk", "this.$store.getters.getPassengersProximityToHazards"]],
        target: ["Master"],
      },
      FRE_126: {
        target: ["Master", "Bridge Officer"],
      },
      FRE_127: {
        target: ["Master"],
      },
      FRE_128: {
        target: ["Master", "Radio-broadcast Officer"],
      },
      FRE_129: {
        target: ["Responsible Crew"],
      },
      FRE_130: {
        target: ["First Mate", "Life-saving Equipment Team"],
      },
      FRE_131: {
        target: ["Master"],
      },
      FRE_132: {
        target: ["Master"],
      },
      FRE_133: {
        target: ["Master"],
      },
      FRE_134: {
        target: ["Master", "Responsible Officer"],
      },
      FRE_135: {
        target: ["Bridge Officer"],
      },
      FRE_136: {
        // info: [["Point", "this.$store.getters.getLocation"]],
        target: ["Patrol Sailor ", "Responsible Crew"],
      },
      FRE_137: {
        info: [["Risk", "this.$store.getters.getAbilityToCommunicate"]],
        target: ["Bridge Officer"],
      },
      FRE_138: {
        info: [["Risk", "this.$store.getters.getEffectiveness"]],
        target: ["Master", "Bridge Officer"],
      },
      FRE_139: {
        target: ["Patrol Sailor ", "Responsible Crew"],
      },
      FRE_140: {
        target: ["Master"],
      },
      FRE_141: {
        target: ["Bridge Officer", "Responsible Crew"],
      },
      FRE_142: {
        target: ["Electrician"],
      },
      FRE_143: {
        target: ["First Mate", "OAK"],
      },
      FRE_144: {
        target: ["Bridge Officer", "Engine Officer"],
      },
      FRE_145: {
        target: ["Master"],
      },
      FRE_146: {
        info: [["Risk", "this.$store.getters.getPassengersProximityToHazards"]],
        target: ["Master"],
      },
      FRE_147: {
        info: [["Risk", "this.$store.getters.getEffectiveness"]],
        target: ["First Mate", "OAK"],
      },
      FRE_148: {
        target: ["First Mate", "OAK"],
      },
      FRE_149: {
        target: ["First Mate", "OAK", "Medical Team"],
      },
      FRE_150: {
        target: ["Master"],
      },
      FRE_151: {
        target: ["Bridge Officer"],
      },
      FRE_152: {
        target: ["Master"],
      },
      FRE_153: {
        target: ["Master", "First Engineer", "First Mate"],
      },
      FRE_154: {
        target: ["First Mate", "OAK"],
      },
      FRE_155: {
        info: [["Risk", "this.$store.getters.getAbilityToCommunicate"]],
        target: ["Master", "First Mate"],
      },
      FRE_156: {
        target: ["Master", "First Mate"],
      },
      FRE_157: {
        info: [["Risk", "this.$store.getters.getPassengersProximityToHazards"]],
        target: ["Master"],
      },
      FRE_158: {
        target: ["Master", "Bridge Officer"],
      },
      FRE_159: {
        target: ["Master"],
      },
      FRE_160: {
        target: ["Master", "Radio-broadcast Officer"],
      },
      FRE_161: {
        target: ["Responsible Crew"],
      },
      FRE_162: {
        target: ["First Mate", "Life-saving Equipment Team"],
      },
      FRE_163: {
        target: ["Master"],
      },
      FRE_164: {
        target: ["Master"],
      },
      FRE_165: {
        target: ["Master"],
      },
      FRE_166: {
        target: ["Master", "Responsible Officer"],
      },
      CLS_001: {
        target: ["Master"],
      },
      CLS_002: {
        target: ["Master"],
      },
      CLS_003: {
        target: ["Master"],
      },
      CLS_004: {
        target: ["Master"],
        info: [["Risk", "this.$store.getters.getStructuralIntegrity"]],
      },
      CLS_005: {
        target: ["Master"],
        info: [["Risk", "this.$store.getters.getHullStatus"]],
      },
      CLS_006: {
        target: ["Master"],
        info: [["Risk", "this.$store.getters.getVesselStatus"]],
      },
      CLS_007: {
        target: ["Master"],
      },
      CLS_008: {
        target: ["Master"],
      },
      CLS_009: {
        target: ["Master"],
      },
      CLS_010: {
        target: ["Master"],
        info: [
          ["Risk", "this.$store.getters.getStructuralIntegrity"],
          ["Risk", "this.$store.getters.getVesselStatus"],
        ],
      },
      CLS_011: {
        target: ["Master"],
        info: [
          // ["Risk", "this.$store.getters.getAbilityToNavigate"]
        ],
      },
      CLS_012: {
        target: ["Master"],
      },
      CLS_013: {
        target: ["Master"],
      },
      CLS_014: {
        target: ["Master"],
        info: [
          ["Risk (MAYBE)", "this.$store.getters.getPassiveContainmentStatus"],
        ],
      },
      CLS_015: {
        target: ["Master"],
      },
      CLS_016: {
        target: ["Master"],
      },
      CLS_017: {
        target: ["Master"],
        info: [["Stigma:", "this.$store.getters.getStigma"]],
      },
      CLS_018: {
        target: ["Master"],
      },
      CLS_019: {
        target: ["Master"],
      },
      CLS_020: {
        target: ["Master"],
      },
      CLS_021: {
        target: ["Master"],
      },
      CLS_022: {
        target: ["Master"],
      },
      CLS_023: {
        target: ["Master"],
      },
      CLS_024: {
        target: ["Master"],
      },
      CLS_025: {
        target: ["Master"],
      },
      CLS_026: {
        target: ["Master"],
      },
      CLS_027: {
        target: ["Master"],
      },
      CLS_028: {
        target: ["Master"],
      },
      CLS_029: {
        target: ["Master"],
      },
      CLS_030: {
        target: ["Master"],
      },
      CLS_031: {
        target: ["Master"],
      },
      CLS_032: {
        target: ["Master"],
      },
      CLS_033: {
        target: ["Master"],
      },
      CLS_034: {
        target: ["Master"],
        info: [
          ["Risk", "this.$store.getters.getPassengersProximityToHazards"],
          // ["Risk", "this.$store.getters.getIncidentSeverity"],
        ],
      },
      CLS_035: {
        target: ["Master"],
      },
      CLS_036: {
        target: ["Master"],
      },
      CLS_037: {
        target: ["Master"],
      },
      CLS_038: {
        target: ["Master"],
      },
      CLS_039: {
        target: ["Master"],
      },
      CLS_040: {
        target: ["Master"],
      },
      CLS_041: {
        target: ["Master"],
      },
      CLS_042: {
        target: ["Master"],
      },
      CLS_043: {
        target: ["Master"],
      },
      CLS_044: {
        target: ["Master"],
      },
      CLS_045: {
        target: ["Master"],
      },
      CLS_046: {
        target: ["Master"],
      },
      CLS_047: {
        target: ["Master"],
      },
      CLS_048: {
        target: ["Master"],
      },
      CLS_049: {
        target: ["Master"],
      },
      CLS_050: {
        target: ["Master"],
      },
      CLS_051: {
        target: ["Master"],
      },
      CLS_052: {
        target: ["Master"],
      },
      CLS_053: {
        target: ["Master"],
      },
      CLS_054: {
        target: ["Master"],
      },
      CLS_055: {
        target: ["Master"],
      },
      CLS_056: {
        target: ["Master"],
      },
      CLS_057: {
        target: ["Master"],
      },
      CLS_058: {
        target: ["Master"],
      },
      CLS_059: {
        target: ["Master"],
      },
      CLS_060: {
        target: ["Master"],
      },
      CLS_061: {
        target: ["Master"],
      },
      CLS_062: {
        target: ["Master"],
      },
      CLS_063: {
        target: ["Master"],
      },
      CLS_064: {
        target: ["Master"],
      },
      CLS_065: {
        target: ["Master"],
      },
      CLS_066: {
        target: ["Master"],
      },
      CLS_067: {
        target: ["Master"],
      },
      CLS_068: {
        target: ["Master"],
        info: [
          ["Risk", "this.$store.getters.getPassengersProximityToHazards"],
          // ["Risk", "this.$store.getters.getIncidentSeverity"],
        ],
      },
      CLS_069: {
        target: ["Master"],
      },
      CLS_070: {
        target: ["Master"],
        info: [
          ["Risk", "this.$store.getters.getHullStatus"],
          ["Risk", "this.$store.getters.getStructuralIntegrity"],
          ["Risk", "this.$store.getters.getStabilityLikelihood"],
          ["Risk", "this.$store.getters.getStabilityToolkit"],
          // ["Risk", "this.$store.getters.getAbilityToNavigate"],
        ],
      },
      CLS_071: {
        target: ["Master"],
      },
      CLS_072: {
        target: ["Master"],
      },
      CLS_073: {
        target: ["Master"],
      },
      CLS_074: {
        target: ["Master"],
      },
      CLS_075: {
        target: ["Master"],
      },
      CLS_076: {
        target: ["Master"],
      },
      CLS_077: {
        target: ["Master"],
      },
      CLS_078: {
        target: ["Master"],
      },
      CLS_079: {
        target: ["Master"],
      },
      CLS_080: {
        target: ["Master"],
      },
      CLS_081: {
        target: ["Master"],
      },
      CLS_082: {
        target: ["Master"],
      },
      CLS_083: {
        target: ["Master"],
      },
      CLS_084: {
        target: ["Master"],
      },
      CLS_085: {
        target: ["Master"],
      },
      CLS_086: {
        target: ["Master"],
      },
      CLS_087: {
        target: ["Master"],
      },
      CLS_088: {
        target: ["Master"],
      },
      CLS_089: {
        target: ["Master"],
      },
      CLS_090: {
        target: ["Master"],
      },
      CLS_091: {
        target: ["Master"],
      },
      CLS_092: {
        target: ["Master"],
      },
      CLS_093: {
        target: ["Master"],
        info: [
          ["Risk", "this.$store.getters.getCriticalSystemsStatus"],
          ["Risk", "this.$store.getters.getHullStatus"],
          // ["Risk", "this.$store.getters.getAbilityToNavigate"],
          ["Risk", "this.$store.getters.getStructuralIntegrity"],
        ],
      },
      CLS_094: {
        target: ["Master"],
        info: [["Risk", "this.$store.getters.getPassengersProximityToHazards"]],
      },
      CLS_095: {
        target: ["Master"],
      },
      CLS_096: {
        target: ["Master"],
      },
      CLS_097: {
        target: ["Master"],
      },
      CLS_098: {
        target: ["Master"],
      },
      CLS_099: {
        target: ["Master"],
      },
      CLS_100: {
        target: ["Master"],
      },
      CLS_101: {
        target: ["Master"],
      },
      CLS_102: {
        target: ["Master"],
      },
      CLS_103: {
        target: ["Master"],
      },
      CLS_104: {
        target: ["Master"],
      },
      CLS_105: {
        target: ["Master"],
      },
      CLS_106: {
        target: ["Master"],
      },
      CLS_107: {
        target: ["Master"],
      },
      CLS_108: {
        target: ["Master"],
      },
      CLS_109: {
        target: ["Master"],
      },
      CLS_110: {
        target: ["Master"],
      },
      CLS_111: {
        target: ["Master"],
      },
      CLS_112: {
        target: ["Master"],
      },
      CLS_113: {
        target: ["Master"],
      },
      CLS_114: {
        target: ["Master"],
      },
      CLS_115: {
        target: ["Master"],
      },
      CLS_116: {
        target: ["Master"],
      },
      CLS_117: {
        target: ["Master"],
      },
      CLS_118: {
        target: ["Master"],
      },
      CLS_119: {
        target: ["Master"],
      },
      CLS_120: {
        target: ["Master"],
      },
    },
    chosenActions: [],

    // alarm_raised: false,
    evacuation_level: 0,
    pimm_level: 0,
    poc_time: "2021-01-04T12:03:03Z",
    poc_control_enabled: true,
  },
  getters: {
    /** Get the state of an evac manager toggler. */
    isTogglerEnabled: (state) => (level_to_qr) => {
      return (state.pimm_level && state.pimm_level != 0 && state.pimm_level == level_to_qr) || state.evacuation_level.toString() == level_to_qr;
    },
    getSituationAssessment: (state) => {
      return {
        soundGA: state.srap.situation_assessment,
        abandon: state.srap.abandon_vessel,
      };
    },
    getSpeed: (state) => {
      return state.spb_legacy.speed;
    },
    getLocation: (state) => {
      return state.spb_legacy.location;
    },
    getEffectiveness: (state) => {
      return state.srap.effectiveness_of_mitigation_measures;
    },
    getSpreading: (state) => {
      return state.srap.spreading;
    },
    getName: (state) => {
      return state.spb_legacy.name;
    },
    getStigma: (state) => {
      return state.spb_legacy.stigma;
    },
    getHullStatus: (state) => {
      return state.srap.hull_status;
    },
    getStabilityLikelihood: (state) => {
      return state.srap.stability;
    },
    getStabilityToolkit: (state) => {
      return state.shipStabilityToolkit;
    },

    getStructuralIntegrity: (state) => {
      return state.srap.structural_integrity;
    },
    // getAbilityToNavigate: (state) => {
    //   return state.srap.ability_to_navigate;
    // },
    getPassengersProximityToHazards: (state) => {
      return state.srap.passengers_proximity_to_hazards;
    },
    getVesselStatus: (state) => {
      return state.srap.vessel_status;
    },
    getCriticalSystemsStatus: (state) => {
      return state.srap.critical_system_status;
    },
    getPassiveContainmentStatus: (state) => {
      return state.srap.status_of_passive_contaimnent;
    },
    getAbilityToCommunicate: (state) => {
      return state.srap.ability_to_communicate;
    },
    getTriggeredSensors: (state) => {
      var triggered = {};

      if (state.sensors === null) return triggered;

      Object.keys(state.sensors).forEach(function (key) {
        if (state.sensors[key].triggered) {
          triggered[key] = state.sensors[key];
        }
      });
      return triggered;
    },
    isSensorTriggered: (state) => (sensor_id) => {
      var sensor = state.sensors[sensor_id];
      return sensor.triggered;
    },
    areSensorsIgnored: (state, getters) => {
      // var sensorTest = {
      //     sensor1: {

      //     },
      //     sensor2: {

      //     }
      // }
      const triggeredSensors = getters.getTriggeredSensors;
      for (const key of Object.keys(triggeredSensors)) {
        if (!state.ignoredSensors.includes(key)) {
          return false;
        }
      }
      return true;
    },
    isSensorIgnored: (state) => (sensor_id) => {
      return state.ignoredSensors.includes(sensor_id);
    },
  },
  mutations: {
    addChosenAction(state, { action, index }) {
      state.chosenActions[index] = action;
    },
    removeChosenAction(state, index) {
      state.chosenActions.splice(index, 1);
    },
    setDSSSuggestions(state, newSug) {
      state.dss_suggestions = newSug;
    },
    setEvacManagerLevel(state, newLevel) {
      if (newLevel == 21 || newLevel == 31) {
        state.pimm_level = newLevel
      } else {
        state.evacuation_level = parseInt(newLevel);
        state.pimm_level = 0;
      }
      console.log(`setEvacManager called ${state} ${newLevel} `)
    },
    setPoCTime(state, newTime) {
      state.poc_time = newTime;
    },
    setPoCEnabled(state, enabled) {
      state.poc_control_enabled = enabled;
    },
    setSSSSnap(state, newSnap) {
      if (newSnap.incident_type != state.sss_snap.incident_type) {
        state.chosenActions = [];
      }
      state.sss_snap = newSnap;
    },
    switchSpeed(state) {
      state.spb_legacy.speed = state.spb_legacy.speed == 14 ? 18 : 14;
    },
    setPassengerIssuesData(state, newPassengerIssues) {
      state.passenger_issues = newPassengerIssues
    },
    setSensorData(state, newSensors) {
      state.sensors = newSensors;
    },
    toggleIgnoredSensor(state, id) {
      if (state.ignoredSensors.includes(id)) {
        const sensor_id = state.ignoredSensors.indexOf(id);
        state.ignoredSensors.splice(sensor_id, 1);
      } else {
        state.ignoredSensors.push(id);
      }
    },
    setSST(state, newSST) {
      state.shipStabilityToolkit = newSST;
    },
    toggleVoyageStatus(state) {
      state.voyageStatus = state.voyageStatus == 0 ? 1 : 0;
    },
    setVoyageStatus(state, newStatus) {
      state.voyageStatus = newStatus;
    },
    setSRAP(state, newSrap) {
      state.srap = {
        ...newSrap,
        risk_of_delay: JSON.parse(newSrap.risk_of_delay)
      }
    }
  },
  actions: {
    commitPoCTime: function () {
      let axios = this.$store.getters.authenticatedAxios;
      axios.put("/poc_time/", { time: this.time });
    },
    toggleVoyageStatus(context) {
      let axios = context.getters.authenticatedAxios;
      axios
        .put("/voyage_status/", {
          voyage_status: context.state.voyageStatus == 0 ? 1 : 0,
        })
        .then(() => {
          context.commit("toggleVoyageStatus");
        });
    },
  },
};
