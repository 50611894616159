<template>
    <div class="container-fluid">
        <div class="form-container">
            <form @submit.prevent="form_submitted()">
                <label>Username:</label>
                <input type="text" required v-model="username">
                <label>Password:</label>
                <input type="password" required v-model="password">
                <button type="submit">Submit</button>
            </form>
        </div>
    </div>
</template>

<style>
.form-container {
    width: 400px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
}
</style>

<script>
import GuestOnly from "../mixins/GuestOnly";
import { authMethods } from "../services/AuthBackend";
export default {
    mixins: [GuestOnly],
    data() {
        return {
            username: "",
            password: ""
        }
    },
    methods: {
        form_submitted() {
            // eslint-disable-next-line no-unused-vars
            authMethods.tryLogin(this.username, this.password).then((auth_obj) => {
                this.$store.commit("login", auth_obj);
                this.$router.push("/");
            })
        }
    }
}
</script>