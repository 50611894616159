<template>
    <button class="btn btn-lg btn-info" @click="commitPoCTime()">{{ displayedTime }}</button>
</template>

<script>
export default {
    name: "TimelineButton",
    props: ["time"],
    computed: {
        displayedTime() {
            var dt = new Date(this.time);
            return dt.toUTCString().split(" ")[4];
        }
    },
    methods: {
        commitPoCTime: function() {
            let axios = this.$store.getters.authenticatedAxios;
            axios.put("/poc_time/", {time: this.time});
        }
    }
}
</script>