import { createStore } from 'vuex'
// import Auth from '../services/AuthBackend';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import incident from './modules/incident';

export default createStore({
  modules: {
    auth: auth,
    incident: incident
  },
  state: {
    nav: {
      sidebarCollapsed: false,
    },
  },
  getters: {},
  mutations: {
    toggleSidebarCollapsed (state) {
      state.nav.sidebarCollapsed = !state.nav.sidebarCollapsed;
    },
  },
  actions: {},
  plugins: [createPersistedState()]
})
