<template>
  <div class="incident-info">
    <div>
      <h3>Incident type: {{ incident_type }}</h3>
      <span
        v-if="
          $store.state.incident.evacuation_level != 21 &&
          $store.state.incident.evacuation_level != 31
        "
        >Operational Level: {{ $store.state.incident.evacuation_level }}</span
      ><span v-else
        >Operational Level:
        {{ Math.trunc($store.state.incident.evacuation_level / 10) }}</span
      >
    </div>
    <span id="span1">Raised at: {{ raised_at }}</span>
  </div>
</template>

<script>
export default {
  name: "IncidentInfo",
  computed: {
    incident_type() {
      var type = this.$store.state.incident.sss_snap.incident_type;
      if (type == null) return "None";

      return type;
    },
    raised_at() {
      var raised_at = this.$store.state.incident.sss_snap.raised_timestamp;
      return raised_at;
    },
  },
  methods: {
    incidentTypeNotify() {
      // Let's check if the browser supports notifications
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      }

      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        new Notification("A new incident has been detected");
      }

      // // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            new Notification("A new incident has been detected");
          }
        });
      }
      // At last, if the user has denied notifications, and you
      // want to be respectful there is no need to bother them anymore.
    },
  },
  watch: {
    incident_type: function (newTriggered, oldTriggered) {
      if (newTriggered != oldTriggered) {
        this.incidentTypeNotify();
      }
    },
  },
};
</script>