import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import DSSWFTScreen from '@/views/DSSWFTScreen.vue';
import PoCTimeline from '@/views/PoCTimeline.vue';
import SmartCameraFeed from '@/views/SmartCameraFeed.vue';
// import store from '@/store/index';
import IncidentAssessment from '@/views/IncidentAssessment.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: "dss_wft",
        component: DSSWFTScreen,
      },
      {
        path: "poc_timeline",
        component: PoCTimeline,
      },
      {
        path: "camera",
        component: SmartCameraFeed,
      },
      {
        path: "incident",
        component: IncidentAssessment,
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})
// router.beforeEach(async (to, from, next) => {
//   const store = await import("@/store/index");
//   debugger;
//   if (!to.meta.guest && store.auth.isLoggedIn())
//     next();
//   else if (to.meta.guest && store.auth.isLoggedIn())
//     next("/");
//   else
//     next("/login");
// })
export default router
