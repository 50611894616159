<template>
    <div id="sidebar" :class="{collapsed: isCollapsed}">
        <div class="brand-logo">
            <img src="@/assets/palaemon_logo_new.jpg">
        </div>
        <nav>
            <ul>
                <li><router-link to="dss_wft">Decision Support Center</router-link></li>
                <li><router-link to="camera">Video Streaming Center</router-link></li>
                <li><router-link to="incident">Incident Assessment</router-link></li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name: "Sidebar",
    computed: {
        isCollapsed() {
            return this.$store.state.nav.sidebarCollapsed;
        }
    }    
}
</script>