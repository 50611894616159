<template>
  <router-view></router-view>
</template>

<script>
import store from './store/index';

export default {
  name: 'PIMM',
  store: store,
}
</script>

<style lang="scss">

</style>
