<template>
  <div class="overflow-auto">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Name</th>
          <th scope="col">Surname</th>
          <th scope="col">Geofence</th>
          <th scope="col">Mobility</th>
          <th scope="col">Health</th>
          <th scope="col">Pregnancy</th>
          <th scope="col">Crew Member</th>
          <th scope="caol">Emergency Role</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <!--create a function that detects if the values are null then display a (-)-->
        <tr v-for="{ event_id, event } in getPassengerIssues" :key="event_id">
          <td>{{ event_id }}</td>
          <td @click="onPassengerSelected(event.passengerId)">
            {{ event.passengerName }}
          </td>
          <td>{{ event.passengerSurname }}</td>
          <td>{{ event.incident.geofence }}</td>
          <td v-if="event.incident.mobility_issues != ''">
            {{ event.incident.mobility_issues }}
          </td>
          <td v-else>-</td>
          <!-- <td v-if="event.incident.health_issues != ''">
            {{ event.incident.health_issues }}
          </td>
          <td v-else>-</td> -->
          <td>Equipment required</td>
          <td v-if="event.incident.pregnancy_status != ''">
            {{ event.incident.pregnancy_status }}
          </td>
          <td v-else>-</td>
          <td>
            {{ event.crew[0].name + " " + event.crew[0].surname }}
          </td>
          <td>{{ event.crew[0].emergencyRole }}</td>
          <td>
            <div class="btn-group">
              <button
                type="button"
                @click="acceptIssue(event_id)"
                class="btn btn-outline-primary btn-sm px-2"
              >
                <i class="far fa-check-circle" aria-hidden="true"></i>
              </button>
              <button
                type="button"
                @click="ignoreIssue(event_id)"
                class="btn btn-outline-danger btn-sm px-2 margin-icon"
              >
                <i class="far fa-times-circle" aria-hidden="true"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["onPassengerSelected"],
  methods: {
    acceptIssue: function (event_id) {
      if (confirm("Are you sure you would like to accept the issue?")) {
        var axiosAuth = this.$store.getters.authenticatedAxios;
        axiosAuth
          .patch("/pameas-notification/" + event_id + "/", {
            type: "PASSENGER_INCIDENT_ASSIGNMENT_ACCEPTENCE",
            not_ignored_issue: true,
          })
          .then((response) => console.log(response));
      }
    },
    ignoreIssue: function (event_id) {
      if (confirm("Are you sure you would like to ignore the issue?")) {
        var axiosAuth = this.$store.getters.authenticatedAxios;
        axiosAuth
          .patch("/pameas-notification/" + event_id + "/", {
            type: "PASSENGER_INCIDENT_ASSIGNMENT_AUTHORIZATION",
            not_ignored_issue: false,
          })
          .then((response) => console.log(response));
      }
    },
  },
  computed: {
    getPassengerIssues: function () {
      let passenger_issues = this.$store.state.incident.passenger_issues;
      let authorized_passeneger_issues = [];
      for (const pi of passenger_issues) {
        if (pi.not_ignored_issue == true) {
          authorized_passeneger_issues.push(pi);
        }
      }
      return authorized_passeneger_issues;
    },
  },
};
</script>

<style>
</style>