<template>
  <template v-if="input != null">
    <div class="dss-container">
      <h4>{{ input.incident }}</h4>
      <strong
        v-if="input.first_text"
        v-html="input.first_text"
        :style="getHiddenStyle"
      ></strong>
      <!-- Print the First text (before the suggestions) -->
      <ul v-if="!hidden" :style="getHiddenStyle">
        <template v-for="s in input.suggestions" :key="s">
          <!-- For every suggestion that tree contains  -->
          <li :id="s.id">
            {{ s.sug }} <br />
            <!-- Print the Suggestion -->

            <ul>
              <li id="ul_risk">
                <div class="d-flex" style="gap: 5px">
                  <template
                    v-for="iv in $store.state.incident.suggestionDataMap[s.id]
                      .info"
                    :key="iv"
                  >
                    <!-- Print extra data referred to the suggestion. -->
                    <IndicatorValue
                      :label="iv[0]"
                      :value="iv[1]"
                    ></IndicatorValue>
                  </template>

                  <template
                    v-for="t in $store.state.incident.suggestionDataMap[s.id]
                      .target"
                    :key="t"
                  >
                    <!-- Print in which target referred to the suggestion.-->
                    <TargetValue :label="t"></TargetValue>
                  </template>
                </div>
              </li>
            </ul>
          </li>
        </template>
      </ul>
      <strong
        :style="getHiddenStyle"
        v-if="input.final_text"
        v-html="input.final_text"
      ></strong>
      <!-- Print the Final text (after the suggestions) -->
      <template v-if="input.actions.length > 0">
        <template v-for="act in input.actions" :key="act.action">
          <!-- After the Final text, action buttons are created and the Master select one -->
          <button
            :style="getHiddenStyle"
            type="button"
            class="button-click"
            @click="choose(act.action)"
            v-html="act.action"
          ></button>
        </template>
        <template v-if="chosenAction != null">
          <button
            type="button"
            class="btn btn-outline-secondary btn-sm"
            v-if="$store.state.incident.chosenActions.length == depth + 1"
            @click="goBack()"
          >
            Go Back
          </button>
          <Suggestion :input="chosenAction" :depth="nextDepth"></Suggestion>
        </template>
      </template>
    </div>
  </template>
</template>

<script>
import IndicatorValue from "./IndicatorValue.vue";
import TargetValue from "./TargetValue.vue";

export default {
  components: { IndicatorValue, TargetValue },
  props: ["input", "depth"],
  computed: {
    nextDepth() {
      return Number(this.depth) + 1;
    },
    chosenAction() {
      if (this.chosen == null) return null;

      var chosenAct = this.input.actions.find(
        (act) => act.action == this.chosen
      );

      return chosenAct;
    },
    getHiddenStyle() {
      return this.hidden
        ? {
            display: "none",
          }
        : {};
    },
  },
  data() {
    return {
      chosen: null,
      hidden: false,
    };
  },
  methods: {
    choose: function (input) {
      this.chosen = input;
      this.hidden = true;
      this.$store.commit("addChosenAction", {
        action: input,
        index: Number(this.depth),
      });
    },
    goBack: function () {
      this.hidden = false;
      this.chosen = null;
      this.$store.commit("removeChosenAction", Number(this.depth));
    },
    getVar: function (input) {
      return eval(input);
    },
  },

  created: function () {
    //A function that checks if a suggestion referred to a target other than the Master
    const chosenAction =
      this.$store.state.incident.chosenActions[Number(this.depth)];
    if (chosenAction) {
      this.choose(chosenAction);
    }
  },
};
</script>