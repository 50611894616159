<template>
  <div class="overflow-auto">
    <table class="table" v-if="passenger != null">
      <tr>
        <th>Name:</th>
        <td>{{ passenger.name }}</td>
      </tr>
      <tr>
        <th>Surname:</th>
        <td>{{ passenger.surname }}</td>
      </tr>
      <tr>
        <th>Gender:</th>
        <td>{{ passenger.gender }}</td>
      </tr>
      <tr>
        <th>Identifier:</th>
        <td>{{ passenger.identifier }}</td>
      </tr>
      <tr>
        <th>Age:</th>
        <td>{{ passenger.age }}</td>
      </tr>
      <tr>
        <th>Ticket Number:</th>
        <td>{{ passenger.ticketNumber }}</td>
      </tr>
      <tr>
        <th>Latest Location:</th>
        <td>{{ passenger.latestLocation }}</td>
      </tr>
      <tr>
        <th>Emergency Contact:</th>
        <td>{{ passenger.emergencyContact }}</td>
      </tr>
      <tr>
        <th>Embarkation Port:</th>
        <td>{{ passenger.embarkation_port }}</td>
      </tr>
      <tr>
        <th>Disembarkation Port:</th>
        <td>{{ passenger.disembarkation_port }}</td>
      </tr>
      <tr>
        <th>Medical Conditions:</th>
        <td>{{ passenger.medical_condnitions }}</td>
      </tr>
      <tr>
        <th>Mobility Issues:</th>
        <td>{{ passenger.mobility_issues }}</td>
      </tr>
      <tr>
        <th>Pregnancy Status:</th>
        <td>{{ passenger.pregnency_data }}</td>
      </tr>
      <tr>
        <th>Preferred Language:</th>
        <td>{{ passenger.preferred_language }}</td>
      </tr>
      <tr>
        <th>Assigned Muster Station:</th>
        <td>{{ passenger.assigned_muster_station }}</td>
      </tr>
    </table>
  </div>
  <button @click="goBack()" type="button" class="btn btn-secondary float-right">
    Back
  </button>
</template>

<script>
export default {
  props: ["passengerId", "goBack"],
  data: function () {
    return {
      passenger: null,
    };
  },
  created() {
    const axios = this.$store.getters.authenticatedAxios;
    axios
      .get(`pameas/get_passenger_details/?passenger_id=${this.passengerId}`)
      .then((response) => {
        this.passenger = response.data;
      });
  },
};
</script>

<style>
</style>