<template>
  <BSModal id="passengerIssuesModal" title="Passenger Issues">
    <PassengerIssuesTable
      v-if="selectedPassengerId == null"
      :onPassengerSelected="onPassengerSelected"
    />
    <PassengerDetails
      v-else
      :passengerId="selectedPassengerId"
      :goBack="onGoBack"
    />
    <template v-slot:footer-buttons>
      <button
        type="button"
        @click="onGoBack()"
        class="btn btn-secondary"
        data-dismiss="modal"
      >
        Close
      </button>
    </template>
  </BSModal>
  <button
    v-bind:class="getClasses"
    data-toggle="modal"
    data-target="#passengerIssuesModal"
  >
    Passenger Issues
  </button>
</template>


<script>
import BSModal from "@/components/misc/bsmodal.vue";
import PassengerIssuesTable from "./PassengerIssuesTable.vue";
import PassengerDetails from "./PassengerDetails.vue";
export default {
  components: {
    BSModal: BSModal,
    PassengerIssuesTable,
    PassengerDetails,
  },
  data: function () {
    return {
      selectedPassengerId: null,
    };
  },
  methods: {
    onPassengerSelected(passengerId) {
      this.selectedPassengerId = passengerId;
    },
    onGoBack() {
      this.selectedPassengerId = null;
    },
    IssueTriggeredNotify() {
      // Let's check if the browser supports notifications
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      }
      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        let notification = new Notification(
          "Be careful. A new Passenger Issue has been triggered!"
        );
        notification;
      }
      // // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            let notification = new Notification(
              "Be careful. A new Passenger Issue has been triggered!"
            );
            notification;
          }
        });
      }
      // At last, if the user has denied notifications, and you
      // want to be respectful there is no need to bother them anymore.
    },
  },
  computed: {
    getPassengerIssues: function () {
      let passenger_issues = this.$store.state.incident.passenger_issues;
      let authorized_passeneger_issues = [];
      for (const pi of passenger_issues) {
        if (pi.not_ignored_issue == true) {
          authorized_passeneger_issues.push(pi);
        }
      }
      return authorized_passeneger_issues;
    },
    getClasses: function () {
      return {
        "btn btn-danger btn-sm mr-1":
          Object.keys(this.getPassengerIssues).length != 0,
        "btn btn-primary btn-sm mr-1":
          Object.keys(this.getPassengerIssues).length == 0,
      };
    },
  },
  watch: {
    getPassengerIssues: function (newTriggered, oldTriggered) {
      if (Object.keys(oldTriggered).length < Object.keys(newTriggered).length) {
        this.IssueTriggeredNotify();
      }
    },
  },
};
</script>

<style>
.margin-icon {
  margin-left: 15px;
}
</style>