<template>
  <BSModal id="mapsModal" title="Deck Maps">
    <div>
      <select class="browser-default custom-select" v-model="selected_number">
        <option selected value="7">Deck 7</option>
        <option value="8">Deck 8</option>
        <option value="9">Deck 9</option>
      </select>
    </div>
    <!-- <template v-for = "[key,value] in Object.entries(passenger_location)" :key="key"> -->
    <!-- </template> -->
    <DeckMapData
      v-if="user_ids != null"
      :passenger_location="passenger_location"
      :deck_number="selected_number"
      :user_ids="user_ids"
      width="1735"
      height="313"
    />
    <div class="same-line p">
      <p><span class="dot-red"></span>Passenger</p>
      <p><span class="dot-blue"></span>Crew Member</p>
    </div>
    <template v-slot:footer-buttons>
      <button type="button" class="btn btn-secondary" data-dismiss="modal">
        Close
      </button>
    </template>
  </BSModal>
  <button
    class="btn btn-primary btn-sm mr-2"
    data-toggle="modal"
    data-target="#mapsModal"
  >
    Map of Decks
  </button>
</template>


<script>
import BSModal from "@/components/misc/bsmodal.vue";
import DeckMapData from "./DeckMapData";

export default {
  components: {
    BSModal: BSModal,
    DeckMapData,
  },
  created() {
    let axiosAuth = this.$store.getters.authenticatedAxios;
    this.updateDeviceIds(axiosAuth);
    this.updateState(axiosAuth);
  },
  methods: {
    updateDeviceIds: async function (axiosAuth) {
      axiosAuth.get("situm/passengers/?type=asdf").then((response) => {
      this.user_ids = response.data
      }).finally(() => {
        setTimeout(() => this.updateDeviceIds(axiosAuth), 10000);
      })
    },
    updateState: async function (axiosAuth) {
      Promise.allSettled([
        axiosAuth.get("situm/passengers/").then(response =>
          this.passenger_location = response.data)
      ]).then(() => {
        setTimeout(() => this.updateState(axiosAuth), 1000);
      })
    },
  },
  data() {
    return {
      selected_number: 7,
      passenger_location: null,
      user_ids: null
    };
  },
};
</script>


<style lang="scss">
.dot-red {
  background-color: red;
  margin-top: 20px;
  margin-right: 5px;
  height: 10px;
  width: 10px;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 10%);
  border-radius: 50%;
  display: inline-block;
}

.dot-blue {
  background-color: blue;
  margin-left: 15px;
  margin-top: 20px;
  margin-right: 5px;
  height: 10px;
  width: 10px;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 10%);
  border-radius: 50%;
  display: inline-block;
}

.same-line p {
  display: inline-block;
  vertical-align: top;
}
</style>