<template>
  <div class="container-fluid">
    <h1 style="text-align: center">Evacuation Manager:</h1>
    <h3 style="text-align: center">
      (Click the following buttons to change the EM's Operational Level)
    </h3>
    <div class="row justify-content-around">
      <EvacuationToggle
        level="0"
        :classes="{
          basic: 'evacuation-toggle-success',
          active: 'active-success',
        }"
        title="Normal Operation"
      >
        <template v-slot:img>
          <img
            class="img-fluid"
            src="https://wi-images.condecdn.net/image/dnwyemkDp8o/crop/3240/f/boatnew.jpg"
          />
        </template>
        No incident has occurred and all of the ship's functions run smoothly.
      </EvacuationToggle>
      <EvacuationToggle
        level="1"
        :classes="{
          basic: 'evacuation-toggle-yellow',
          active: 'active-yellow',
        }"
        title="Situation Assessment"
      >
        <template v-slot:img>
          <img
            class="img-fluid"
            src="https://crew-center.com/sites/default/files/diamond-pricess-crew-cleaning-plates-in-pantry_1.jpg"
          />
        </template>
        <template v-if="$store.getters.isTogglerEnabled(1)">
          <a
            class="btn btn-info btn-sm"
            target="_blank"
            rel="noopener noreferrer"
            href="https://dispatcher-1.ea-1.eu-west-1.agnet.com/"
            >Team Dispacher</a
          >
        </template>
        An alarm has been raised and the related incident is being investigated
        in correlation with the ship's condition.
      </EvacuationToggle>
      <EvacuationToggle
        level="2"
        :classes="{
          basic: 'evacuation-toggle-warning',
          active: 'active-warning',
        }"
        title="Activation of Evacuation Protocol"
      >
        <template v-slot:img>
          <img
            class="img-fluid"
            src="https://www.kidcheck.com/wp-content/uploads/2017/07/Emergency-Action-Plan.jpg"
          />
        </template>
        The decision of evacuation has been confirmed and the summoning of all
        passengers and personnel has started.
      </EvacuationToggle>
      <EvacuationToggle
        level="21"
        :classes="{
          basic: 'evacuation-toggle-warning',
          active: 'active-warning',
        }"
        title="Alert Passengers"
      >
        <template v-slot:img>
          <img
            class="img-fluid"
            src="https://st.depositphotos.com/1186248/2751/i/600/depositphotos_27516585-stock-photo-alert.jpg"
          />
        </template>
        The decision of evacuation has been confirmed and the summoning of all
        passengers and personnel has started.
      </EvacuationToggle>
      <EvacuationToggle
        level="3"
        :classes="{
          basic: 'evacuation-toggle-warning',
          active: 'active-warning',
        }"
        title="Passenger Mustering"
      >
        <template v-slot:img>
          <img
            class="img-fluid"
            src="https://www.southwestmaritimeacademy.com/wp-content/uploads/2013/11/cruise-ship-muster-drill-news.jpg"
          />
        </template>
        The decision of evacuation has been confirmed and the summoning of all
        passengers and personnel has started.
      </EvacuationToggle>
      <EvacuationToggle
        level="31"
        :classes="{
          basic: 'evacuation-toggle-warning',
          active: 'active-warning',
        }"
        title="Mustering Completed"
      >
        <template v-slot:img>
          <img
            class="img-fluid"
            src="https://thumbs.dreamstime.com/b/muster-station-assembly-point-all-crew-passenger-to-be-gathered-emergencies-view-onboard-offshore-marine-207456461.jpg"
          />
        </template>
        <template v-if="$store.getters.isTogglerEnabled(31)">
          <MusteredPassengers />
        </template>
        The Mustering phase has been confirmed and the embarkation of all
        passengers has started.
      </EvacuationToggle>

      <EvacuationToggle
        level="4"
        :classes="{
          basic: 'evacuation-toggle-danger',
          active: 'active-danger',
        }"
        title="Embarkation"
      >
        <template v-slot:img>
          <img
            class="img-fluid"
            src="https://mediacache4.viking-life.com/93/96/18d2-0a6b-4d6e-8f00-2865167f0d8e/viking%20vemc_dfds%20pearl%20fayard_2019_side%201_pro4028.jpg"
          />
        </template>
        The embarkation of people into the MEV's is taking place.
      </EvacuationToggle>

      <EvacuationToggle
        level="5"
        :classes="{
          basic: 'evacuation-toggle-info',
          active: 'active-info',
        }"
        title="Ship Abandonment"
      >
        <template v-slot:img>
          <img
            class="img-fluid"
            src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Collision_of_Costa_Concordia_11.jpg"
          />
        </template>
        The ship has been successfully evacuated.
      </EvacuationToggle>
    </div>
  </div>
</template>

<script>
import EvacuationToggle from "./evacuation-manager/evacuation-toggle";
import MusteredPassengers from "./evacuation-manager/MusteredPassengers.vue";
export default {
  name: "EvacuationManager",
  components: {
    EvacuationToggle: EvacuationToggle,
    MusteredPassengers,
  },
};
</script>