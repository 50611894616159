import axios from "axios";
import {BACKEND_ADDR} from "@/services/AuthBackend.js";

export default {
    state: {
        logged_in: null,
    },
    mutations: {
        login: (state, auth_obj) => state.logged_in = auth_obj,
        logout: (state) => state.logged_in = null,
    },
    getters: {
        isLoggedIn: (state) => {
            return (state.logged_in !== null);
        },
        authenticatedAxios: (state) => {
            var axios_auth = axios.create({
                baseURL: BACKEND_ADDR,
                headers: {
                    "Authorization": "Bearer " + state.logged_in.access,
                },
            });

            // axios_auth.interceptors.response.use(
            //     interceptors.success_interceptor, 
            //     interceptors.error_interceptor);
            return axios_auth;
        }
    }
}