<template>
  <BSModal id="sensorsModal" title="Triggered Smoke Detectors">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Label</th>
          <th scope="col">Ignore</th>
          <th scope="col">Block Area</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(sensor, id) in getTriggeredSensors"
          :key="id"
          :class="{
            'bg-danger':
              isSensorTriggered(id) && !$store.getters.isSensorIgnored(id),
            'bg-secondary': $store.getters.isSensorIgnored(id),
          }"
        >
          <td>{{ sensor.label }}</td>
          <td>
            <button
              type="button"
              class="btn btn-outline-dark"
              @click="$store.commit('toggleIgnoredSensor', id)"
            >
              <template v-if="$store.getters.isSensorIgnored(id)">
                Ignored
              </template>
              <template v-else> Ignore </template>
            </button>
          </td>
          <td>
            <button type="button" @click="onCloseGeofence(sensor.geofence_affected)" class="btn btn-outline-dark">Block</button>
          </td>
        </tr>
      </tbody>
    </table>
    <template v-slot:footer-buttons>
      <button
        type="button"
        class="btn btn-danger"
        @click="declareFire"
        v-if="fireExists && !$store.getters.areSensorsIgnored"
      >
        Declare
      </button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal">
        Close
      </button>
    </template>
  </BSModal>
  <button
    class="btn btn-primary btn-sm"
    v-bind:class="getClasses"
    data-toggle="modal"
    data-target="#sensorsModal"
  >
    Smoke Detectors
  </button>
</template>


<script>
import BSModal from "@/components/misc/bsmodal.vue";
import { closeGeofence } from "@/utils.js";
export default {
  components: {
    BSModal: BSModal,
  },
  methods: {
    isSensorTriggered: function (id) {
      //debugger;
      return this.$store.getters.isSensorTriggered(id);
    },
    onCloseGeofence: function (geofence) {
      var axiosAuth = this.$store.getters.authenticatedAxios;
      closeGeofence(axiosAuth, geofence)
    },
    sensorTriggeredNotify() {
      // Let's check if the browser supports notifications
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      }

      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        let notification = new Notification(
          "Be careful. A new smoke sensor has been triggered!"
        );
        notification;
      }

      // // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            let notification = new Notification(
              "Be careful. A new smoke sensor has been triggered!"
            );
            notification;
          }
        });
      }
      // At last, if the user has denied notifications, and you
      // want to be respectful there is no need to bother them anymore.
    },
    declareFire: function () {
      var axiosAuth = this.$store.getters.authenticatedAxios;
      axiosAuth
        .post("/declare-fire/", {
          triggeredSensors: this.getTriggeredSensors,
        }) // eslint-disable-next-line no-unused-vars
        .then((_) => {
          alert("Alarm Issued");
        });
    },
  },
  computed: {
    getTriggeredSensors: function () {
      let sensor = this.$store.getters.getTriggeredSensors;
      return sensor;
    },
    getClasses: function () {
      return {
        "btn-primary": Object.keys(this.getTriggeredSensors).length == 0,
        "btn-danger":
          Object.keys(this.getTriggeredSensors).length != 0 &&
          !this.$store.getters.areSensorsIgnored,
        "btn-warning":
          Object.keys(this.getTriggeredSensors).length != 0 &&
          this.$store.getters.areSensorsIgnored,
      };
    },
    getSensors: function () {
      let sensors = this.$store.state.incident.sensors;
      return sensors;
    },
    fireExists: function () {
      return Object.keys(this.getTriggeredSensors).length > 0;
    },
  },
  watch: {
    getTriggeredSensors: function (newTriggered, oldTriggered) {
      if (Object.keys(oldTriggered).length < Object.keys(newTriggered).length) {
        this.sensorTriggeredNotify();
      }
    },
  },
};
</script>