<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

    .weather-col-border {
        border-right: 1px solid gray;
    }

    .weather-col-header {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        color: #A7A7A7;
        opacity: 1;
    }

    .suggested-actions-col {
        background-color: #F2FBFF;
        padding: 10px;
    }

    .risks-col {
        background-color: #FFDBDB;
        padding: 10px;
    }

    .margin-bottom {
        margin-bottom: 1px;
    }

    .headers {
         font-family: 'Roboto', sans-serif;
         font-size: 22px;
         font-weight: bolder;
         text-align: left;
         color: #3B3B3B;
         opacity: 1;
    }

    .sub-headers {
         font-family: 'Roboto', sans-serif;
         font-size: 20px;
         font-weight: 600;
         text-align: left;
    }

    .paragraphs-bold {
         font-family: 'Roboto', sans-serif;
         font-size: 16px;
         font-weight: bolder;
         text-align: left;
    }

    .paragraphs-light {
         font-family: 'Roboto', sans-serif;
         font-size: 16px;
         font-weight: 600;
         text-align: left;
    }
</style>

<!-- TODO integrate js variables for values-->
<template>
    <template v-if="actionPlanFollow != null && isValidIncident">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <h3>Weather Forecast Toolkit</h3>
                </div>
            </div>
            <div class="container-fluid" style="background-color:white; padding:20px">
                <p class="headers">Current Weather</p>
                <div class="row">
                    <div class="col-3 weather-col-border">
                        <p class="weather-col-header" style="text-align:center">Sig wave height</p>
                        <p style="text-align:center"> {{currentSnap.significant_wave_height}} ft </p>
                    </div>
                    <div class="col-3 weather-col-border">
                        <p class="weather-col-header" style="text-align:center">Wind Direction</p>
                        <p style="text-align:center"> {{currentSnap.wind_direction}} deg </p>
                    </div>
                    <div class="col-3 weather-col-border">
                        <p class="weather-col-header" style="text-align:center">Wind Speed</p>
                        <p style="text-align:center"> {{currentSnap.wind_speed}} kt </p>
                    </div>
                    <div class="col-3">
                        <p class="weather-col-header" style="text-align:center">Air Temperature</p>
                        <p style="text-align:center"> {{currentSnap.air_temp}} F </p>
                    </div>
                </div>
                <br>  
                <div class="row">
                    <div class="col-6 p-2">
                        <div class="suggested-actions-col">
                            <p class="sub-headers">Suggested Actions</p>
                            <p class="paragraphs-bold" v-for="action in actionPlanFollow.actions" :key="action"><span>&#8226;</span>{{action}}</p>
                            <p class="margin-bottom">Similar case: {{ actionPlanFollow.Similar_Case }}</p>
                            <p class=" margin-bottom">Similarity: {{actionPlanFollow.Similarity}}</p>
                        </div>
                    </div>
                    <div class="col-6 p-2">
                        <div class="risks-col">
                            <p class="sub-headers">Risks</p>
                            <p class="paragraphs-bold" v-for="action in actionPlanNotFollow.actions" :key="action"><span>&#8226;</span>{{action}}</p>
                            <p class="margin-bottom">Similar case: {{ actionPlanNotFollow.Similar_Case }}</p>
                            <p class="margin-bottom">Similarity: {{actionPlanNotFollow.Similarity}}</p>
                        </div>
                    </div>
                </div>
                <br>
                <h4 class="headers">Possible outcomes</h4>
                <div class="row">
                    <div class="col-6" v-if="wind_charts !== null">
                        <!-- <img class="img-fluid" src="https://d1whtlypfis84e.cloudfront.net/guides/wp-content/uploads/2018/09/26173831/histogram2.png" alt=""> -->
                        <highcharts :options="wind_charts" />
                    </div>
                    <div class="col-6" v-if="visibility_charts !== null">
                        <highcharts :options="visibility_charts" />
                    </div>  
                </div>
            </div>
            <!-- <template v-if="actionPlanFollow != null && isValidIncident">
                    <div class="col-12">
                        <h1 class="text-center">Weather Forecast Toolkit:</h1>
                    </div>
                    <div class="col-11 pimm-modal weather-data-container">
                        <h2>Current Weather:</h2>
                        <div class="weather-data-points-container">
                            <DataPoint title="Sig. Wave Height" unit="ft." :value="currentSnap.significant_wave_height"></DataPoint>
                            <DataPoint title="Wind Direction" unit="deg." :value="currentSnap.wind_direction"></DataPoint>
                            <DataPoint title="Wind Speed" unit="kt." :value="currentSnap.wind_speed"></DataPoint>
                            <DataPoint title="Air Temperature" unit="F" :value="currentSnap.air_temp"></DataPoint>
                        </div>
                    </div> -->
                    <!-- this div was commented -->
                    <!-- <div class="col-6">
                        Welcome to the Weather Forecast Toolkit! The WFT reads real-time weather data and preduces plans of action that the crew can perform to mitigate the current situation.
                    </div> -->
                <!-- <div class="row justify-content-around">
                    <div class="col-md-5 action-plan-follow">
                        <h2>Suggested Action:</h2>
                        <ul>
                            <li v-for="action in actionPlanFollow.actions" :key="action" v-html="action"></li>
                            <li><b>Similarity: {{ actionPlanFollow.Similarity }}</b></li>
                            <li><b>Similar Case: {{ actionPlanFollow.Similar_Case }}</b></li>
                        </ul>
                    </div>
                    <div class="col-md-5 action-plan-not-follow">
                        <h2>Risks:</h2>
                        <ul>
                            <li v-for="action in actionPlanNotFollow.actions" :key="action" v-html="action"></li>
                            <li><b>Similarity: {{ actionPlanNotFollow.Similarity }}</b></li>
                            <li><b>Similar Case: {{ actionPlanNotFollow.Similar_Case }}</b></li>
                        </ul>
                    </div>
                </div>
            </template>
            <template v-else-if="isValidIncident && actionPlanFollow == null">
                <h1>Weather Data Unavailable</h1>
            </template>
            <template v-else>
                <h1>WFT Off</h1>
            </template> -->
        </div>
    </template>
    <template v-else-if="isValidIncident && actionPlanFollow == null">
        <h1>Weather Data Unavailable</h1>
    </template>
    <template v-else>
        <h1>WFT Off</h1>
    </template>
</template>

<script>
// import DataPoint from "@/components/wft/DataPoint";
import {Chart} from 'highcharts-vue';
export default {
    name: "WFT",
    components: {
        // DataPoint: DataPoint
        highcharts: Chart
    },
    created: function() {
        // eslint-disable-next-line no-unused-vars
        var axiosAuth = this.$store.getters.authenticatedAxios;
        this.updateState(axiosAuth);
    },
    beforeUnmount() {
        /// Stops the AJAX calls.
        this.stop = true;
    },
    data() {
        return {
            currentSnap: null,
            actionPlanFollow: null,
            actionPlanNotFollow: null,
            stop: false,
            wind_charts: null,
            visibility_charts: null,
        };
    },
    computed: {
        isValidIncident() {
            /// Returns true if the incident is a fire incident.
            return this.$store.state.incident.sss_snap.incident_type == "Fire";
        }
    },
    methods: {
        updateState(axiosAuth) {
            axiosAuth.get("/wft/latest").then((response) =>{
                this.currentSnap = response.data;
                if (Object.prototype.hasOwnProperty.call(response.data, "action_plan_follow")){
                    this.actionPlanFollow = JSON.parse(response.data.action_plan_follow);
                    this.actionPlanNotFollow = JSON.parse(response.data.action_plan_not_follow);
                } else {
                    this.actionPlanFollow = null;
                    this.actionPlanNotFollow = null;
                }
                if (Object.prototype.hasOwnProperty.call(response.data, "wind_charts")) {
                    this.wind_charts = JSON.parse(response.data.wind_charts);
                    this.wind_charts.series = this.wind_charts.series.map((e) => {
                        if (typeof(e.data) === 'number'){
                            e.data = [e.data];
                            return { ...e };
                        }
                        return e;
                    })
                }
                if (Object.prototype.hasOwnProperty.call(response.data, "visibility_charts")) {
                    this.visibility_charts = JSON.parse(response.data.visibility_charts);
                    this.visibility_charts.series = this.visibility_charts.series.map((e) => {
                        if (typeof(e.data) === 'number'){
                            e.data = [e.data];
                            return { ...e };
                        }
                        return e;
                    })
                }
                
            }).finally(() => {
                if (!this.stop)
                    setTimeout(() => this.updateState(axiosAuth), 1000);
            })


        }
    }
}
</script>