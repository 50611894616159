import Axios from "axios";

const client = Axios.create({
  baseURL: ""
});

export default async function logger(contents) {
  await client.post("/entry/", {
    contents,
    category: "PIMM"
  })
}