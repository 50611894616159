export async function closeGeofence (axiosAuth, geofence) {
  if (
    confirm("Are you sure you would like to close this Muster Station?")
  ) {
    await axiosAuth.post("/block-geofence/", {
      geofence,
      status: "blocked",
    });
  }
}

export function showNotification (text) {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      new Notification(text);
    }

    // // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          new Notification(text);
        }
      });
    }
    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
}