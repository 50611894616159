<template>
  <div class="container-fluid">
    <div class="col">
      <h4 class="margin-diagram-title">Diagram of Danger in Ship</h4>
      <SRAPRiskDiagram />
    </div>
    <div class="col">
      <h4 class="margin">PaMEAS</h4>
      <DeckMaps />
      <CrewMembers class="margin-enable-button" />
      <PassengersPerGeofence />
      <!--<EnableGeneralAlarm class="margin-enable-button" />-->
      <PAMEASMustering />
    </div>
  </div>
</template>

<script>
import SRAPRiskDiagram from "@/components/SRAPRiskDiagram/SRAPRiskDiagram";
import PAMEASMustering from "@/components/PAMEASMustering/muster-station/MusterStationData";
import DeckMaps from "../components/PAMEASMustering/deck-map/DeckMaps.vue";
import CrewMembers from "../components/PAMEASMustering/CrewMembers.vue";
// import EnableGeneralAlarm from "../components/PAMEASMustering/EnableGeneralAlarm.vue";
import PassengersPerGeofence from "@/components/PassengersPerGeofence/PassengersPerGeofence.vue";
export default {
  components: {
    SRAPRiskDiagram,
    PAMEASMustering,
    DeckMaps,
    CrewMembers,
    PassengersPerGeofence
    // EnableGeneralAlarm,
  },
};
</script>

<style lang="scss">
.margin {
  margin-top: 80px;
  margin-left: 30px;
  margin-right: 15px;
  display: inline-flex;
  flex-direction: row;
}

.margin-diagram-title {
  margin-top: 10px;
  margin-left: 30px;
}

.margin-enable-button {
  margin-left: 20px;
  display: inline-flex;
  flex-direction: row;
}
</style>