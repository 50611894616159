<template>
    <div id="main-content">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Content",

}
</script>