<template>
  <div
    v-if="
      p != null && ((isPassenger) || !isPassenger || isUnknown)
    "
    class="person-dot"
    v-bind:class="{
      'passenger': isPassenger,
      'crew-member': !isPassenger,
      'unknown': isUnknown
    }"
    :style="getPosition"
  ></div>
</template>

<style lang="scss">
.deck-diagram .person-dot {
  position: absolute;
  width: 1px;
  height: 1px;
  border-radius: 50%;
}

.crew-member {
  border: 3px solid blue;
}

.unknown{
  border: 3px solid gray;
}

.passenger {
  border: 3px solid red;
}

</style>

<script>
export default {
  props: ["p", "imageWidth", "imageHeight", "deck_number"],
  computed: {
    getPosition() {
      var meterw;
      // eslint-disable-next-line no-unused-vars
      var meterh;
      if (this.deck_number == 7) {
        meterw = this.imageWidth / 203;
        meterh = this.imageHeight / 33;
      } else if (this.deck_number == 8) {
        meterw = this.imageWidth / 193.5;
        meterh = this.imageHeight / 40;
      } else if (this.deck_number == 9) {
        meterw = this.imageWidth / 193;
        meterh = this.imageHeight / 32;
        // meterw = this.imageWidth / 163;
        // meterh = this.imageHeight / 32;
      }

      var finalPassangerw = meterw * this.p.xloc; //position w unscaled
      // var finalPassangerh = meterh * this.p.yloc; //position h unscaled
      var finalPassangerh = meterw * this.p.yloc; //position h unscaled
      var scaledw = (finalPassangerw * 100) / this.imageWidth;
      var scaledh = (finalPassangerh * 100) / this.imageHeight;
      return {
        bottom: `${scaledh}%`,
        left: `${scaledw}%`,
      };
    },
    isPassenger() {
      return this.p.type.toLowerCase() == "passenger";
    },
    isUnknown() {
      return this.p.type.toLowerCase() == "unknown";
    },
    isPassengerLost() {
      var geofence = this.p.geofence;
      var assignedDeck = this.p.assignedMS;

      if (geofence != assignedDeck) {
        return true;
      } else {
        return false;
      }
    },
    filterDeckBasedOnPassDeck() {
      var currentPassengerDeck = this.p.geofence.charAt(0);
      //debugger;
      if (currentPassengerDeck == this.deck_number) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>