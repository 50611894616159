<template>
  <button
    @click="onButtonClick()"
    v-html="buttonText"
    class="btn btn-sm mr-1"
    :class="buttonStyle"
  ></button>
</template>

<script>
export default {
  created() {
    let axiosAuth = this.$store.getters.authenticatedAxios;
    this.updateState(axiosAuth);
  },
  unmounted() {
    this.stop = true;
  },
  data() {
    return {
      stop: false,
    };
  },
  computed: {
    buttonText() {
      return this.$store.state.incident.voyageStatus == 0
        ? "Start Voyage"
        : "Stop Voyage";
    },
    voyageStarted() {
      return this.$store.state.incident.voyageStatus == 1;
    },
    buttonStyle() {
      let stl = "btn-";
      stl += this.voyageStarted ? "success" : "danger";
      return [stl];
    },
  },
  methods: {
    onButtonClick() {
      if (
        (this.voyageStarted &&
          confirm("Are you sure you'd like to end the voyage?")) ||
        !this.voyageStarted
      )
        this.$store.dispatch("toggleVoyageStatus");
    },
    updateState(axiosAuth) {
      axiosAuth.get("/voyage_status/").then((resp) => {
        this.$store.commit("setVoyageStatus", resp.data.voyage_status);
        if (!this.stop) {
          setTimeout(() => this.updateState(axiosAuth), 1000);
        }
      });
    },
  },
};
</script>