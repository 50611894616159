<template>
  <div class="container-fluid" v-if="muster_station !== null">
    <div class="row">
      <template
        v-for="[key, value] in Object.entries(muster_station)"
        :key="key"
      >
        <MusterStationInfo :value="value" :station_name="key" />
      </template>
    </div>
  </div>
</template>


<style lang="scss">
.center {
  margin: 10px;
  width: 80%;
  padding: 5px;
}

.container {
  display: inline-flex;
  background: #fff;
  flex-direction: row; /* change this to row instead of 'column' */
}

.item {
  margin: 12px;
  width: 100%; /* I added */
}

.item2 {
  width: 35%;
  height: 10%;
  text-align: center;
}

.info {
  margin: -5px;
}

.info-last {
  margin-top: -5px;
  margin-left: -5px;
}
</style>

<script>
import MusterStationInfo from "./MusterStationInfo.vue";

export default {
  created() {
    let axiosAuth = this.$store.getters.authenticatedAxios;
    this.updateState(axiosAuth);
  },
  methods: {
    updateState: function (axiosAuth) {
      axiosAuth.get("pameas/muster_station_data/").then((response) => {
        //debugger;
        this.muster_station = response.data;
        if (!this.stop) {
          setTimeout(() => this.updateState(axiosAuth), 1000);
        }
      });
    },
  },
  data() {
    return {
      muster_station: null,
    };
  },
  components: { MusterStationInfo },
};
</script>