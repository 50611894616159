<template>
  <div
    ref="container"
    class="col mr-3 mt-2"
    :class="[classes.basic, getIsActive() ? classes.active : '']"
    @click="onEnable"
  >
    <!-- <div class="col-12"> -->
    <p
      style="text-align: center; font-size: large; font-weight: bold"
      v-html="title"
    ></p>
    <!-- </div> -->
    <!-- <div class="col-12"> -->
    <template v-if="getIsActive()">
      <h3 class="active">Active</h3>
    </template>
    <!-- </div> -->
    <!-- <div class="col-12"> -->
    <slot name="img"></slot>
    <slot></slot>
    <!-- </div> -->
  </div>
</template>

<script>
import logger from '../../logger';
export default {
  name: "EvacuationToggle",
  props: {
    title: {
      type: String,
    },
    classes: {
      type: Object,
    },
    level: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    onEnable: function () {
      if (
        !this.getIsActive() &&
        confirm("Are you sure you'd like to change the operation level?")
      ) {
        let level = this.level;
        // let coordinator_levels = ["1", "2", "3", "4", "5"];
        this.setLevel();
        var axiosAuth = this.$store.getters.authenticatedAxios;
        console.log(typeof level);
        if (level != 21 && level != 31) {
          axiosAuth.put("/evac_coordinator/", { level: level });
        }

        // if (coordinator_levels.includes(level)) {
        //   axiosAuth.put("/evac_coordinator/", { level: level });
        //   debugger;
        // } else {
        //   axiosAuth.get("pameas/enable_general_alarm/").then((response) => {
        //     this.result = response.data;
        //     debugger;
        //   });
        // }

        if (level == 21) {
          axiosAuth.get("pameas/enable_general_alarm/").then((response) => {
            this.result = response.data;
            debugger;
          });
        }

        if (level == 31) {
          logger("MUSTERING_COMPLETED")
        }

        // if (level == 3) {
        //   axiosAuth
        //     .get("pameas/send_passenger_instructions/")
        //     .then((response) => {
        //       this.result = response.data;
        //     });
        // }
      }
    },
    getIsActive: function () {
      return this.$store.getters.isTogglerEnabled(this.level);
    },
    setLevel: function () {
      return this.$store.commit("setEvacManagerLevel", this.level);
    },
  },
};
</script>