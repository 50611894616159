<template>
  <div id="top-nav">
    <div class="toggler-icon">
      <img src="@/assets/toggler_icon.png" @click="toggleSidebar()" />
    </div>
    <IncidentInfo></IncidentInfo>
    <slot></slot>
    <div class="align-right">
      <PassengerIssues />
      <VoyageStartStop />
      <Sensors />

      <button class="logout-btn ml-2" @click="logout">Logout</button>
    </div>
  </div>
</template>

<script>
import IncidentInfo from "@/components/IncidentInfo.vue";
import Sensors from "./Sensors/Sensors.vue";
import VoyageStartStop from "./VoyageStartStop.vue";
import PassengerIssues from "./PassengerIssues/PassengerIssues.vue";

export default {
  name: "TopNav",
  components: {
    IncidentInfo: IncidentInfo,
    Sensors,
    VoyageStartStop,
    PassengerIssues,
  },
  methods: {
    toggleSidebar() {
      this.$store.commit("toggleSidebarCollapsed");
    },
    logout() {
      this.$store.commit("logout");
      this.$router.push("/login");
    },
  },
};
</script>