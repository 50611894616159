<template>
  <BSModal id="crewMembersModal" title="Crew Member List">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Surname</th>
          <th scope="col">Team</th>
          <th scope="col">Location</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(member, id) in crewMembers" :key="id">
          <td>{{ member.name }}</td>
          <td>{{ member.surname }}</td>
          <td>{{ member.team }}</td>
          <td>{{ member.location }}</td>
        </tr>
      </tbody>
    </table>
    <template v-slot:footer-buttons>
      <button type="button" class="btn btn-secondary" data-dismiss="modal">
        Close
      </button>
    </template>
  </BSModal>
  <button
    class="btn btn-primary btn-sm"
    data-toggle="modal"
    data-target="#crewMembersModal"
  >
    Crew Member List
  </button>
</template>
  
  
  <script>
import BSModal from "@/components/misc/bsmodal.vue";

export default {
  components: {
    BSModal: BSModal,
  },
  data() {
    return {
      crewMembers: null,
    };
  },
  created: function () {
    const axios = this.$store.getters.authenticatedAxios;
    axios.get("pameas/crew_members").then((response) => {
      this.crewMembers = response.data;
    });
  },
};
</script>