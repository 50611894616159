<template>
    <h1> Ship Stability Toolkit</h1>
    <p>Content of the Ship Stability Toolkit</p>
    <div class="container-fluid">
        <div class="row justify-content-between">
            <div class="col-4 d-flex flex-column justify-content-center align-items-start">
                <ul style="padding: 0">
                    <li>Heave: {{ $store.state.incident.shipStabilityToolkit.heave }}m</li>
                    <li>Sway: {{ $store.state.incident.shipStabilityToolkit.sway }}m</li>
                    <li>Pitch: {{ $store.state.incident.shipStabilityToolkit.pitch }}m</li>
                    <li>Yaw: {{ $store.state.incident.shipStabilityToolkit.yaw }}&deg;</li>
                    <li>Surge: {{ $store.state.incident.shipStabilityToolkit.surge }}&deg;</li>
                    <li>Roll: {{ $store.state.incident.shipStabilityToolkit.roll }}&deg;</li>
                </ul>
            </div>
            <div class="col-6">
                <img class="ship-diagram" src="@/assets/sst_figure.png">
            </div>
        </div>
        
    </div>
    
</template>

<style scoped>
    .ship-diagram {
        display: block;
        width: 100%;
    }
</style>

<script>
    export default {
        name: "SST",
        components: {

        },
        data() {
            return {
                stopped: false,
            };
        },
        created() {
            var axiosAuth = this.$store.getters.authenticatedAxios;

            this.updateSST(axiosAuth)
        },
        methods: {
            updateSST: function (axiosAuth) {
                axiosAuth.get("/sst/").then(response => {
                    this.$store.commit("setSST", response.data)

                    setTimeout(() => {
                        this.updateSST(axiosAuth)
                    }, 1000)
                })
            }
        }
    }
</script>
