<template>
  <div class="deck-diagram">
    <img :src="require('@/assets/deck' + deck_number + '.png')" />
    <PassengerDot
      :key="p"
      v-for="p in passenger_location"
      :deck_number="deck_number"
      :p="getFullPassenger(p)"
      :imageWidth="width"
      :imageHeight="height"
    />
  </div>
  <!-- change passengers to passenger_location on line 6-->
</template>

<style lang="scss">
.deck-diagram {
  position: relative;
  width: 100%;
}

.deck-diagram img {
  display: block;
  width: 100%;
}
</style>

<script>
import PassengerDot from "./PassengerDot.vue";

export default {
  props: ["deck_number", "value", "width", "height", "passenger_location", "user_ids"],
  methods: {
    getFullPassenger(passenger) {
      if (passenger.id in this.user_ids){
        return {
          ...passenger,
          type: this.user_ids[passenger.id]
        }
      }
      
      return {
        ...passenger,
        type: "unknown"
      };
    }
  },
  components: {
    PassengerDot,
  },
  data() {
    return {};
  },
};
</script>