<template>
  <div class="ship-diagram">
    <img :src="require('@/assets/Elyros.png')" />

    <!-- change the name of deck when srap give the json with deck names -->

    <!-- Above Garage Space -->
    <!-- <ShipArea deck="Z1D3" left="148" top="178" end="1357" endv="228" /> -->
    <!--Z1D6-->
    <ShipArea deck="Z1D3" left="1358" top="178" end="1571" endv="204" />
    <!--Z1D5-->
    <ShipArea deck="Z1D3" left="1358" top="204" end="1571" endv="228" />

    <!-- Below Garage Space -->
    <!-- <ShipArea deck="Z1D3" left="21" top="229" end="1657" endv="277" /> -->

    <!--Steering G. RM-->
    <!-- <ShipArea deck="Z1D3" left="22" top="279" end="175" endv="302" /> -->
    <!--Shaft RM-->
    <!-- <ShipArea deck="Z1D3" left="314" top="279" end="396" endv="358" /> -->
    <!--Engine RM-->
    <!-- <ShipArea deck="Z1D3" left="397" top="279" end="625" endv="358" /> -->
    <!--Aux Engine RM-->
    <!-- <ShipArea deck="Z1D3" left="626" top="279" end="747" endv="358" /> -->
    <!--Lower Garage-->
    <!-- <ShipArea deck="Z1D3" left="747" top="279" end="1418" endv="310" /> -->
    <!--Pump RM-->
    <!-- <ShipArea deck="Z1D3" left="749" top="311" end="838" endv="358" /> -->
    <!--Bow Thr. RM-->
    <!-- <ShipArea deck="Z1D3" left="1572" top="279" end="1686" endv="315" /> -->

    <!--MS D -->
    <ShipArea deck="MSD" left="56" top="151" end="350" endv="176" />
    <!--MS C -->
    <ShipArea deck="MSC" left="351" top="151" end="747" endv="176" />
    <!--MS B -->
    <ShipArea deck="MSB" left="748" top="151" end="1113" endv="176" />
    <!--MS A -->
    <ShipArea deck="MSA" left="1114" top="151" end="1560" endv="176" />

    <!--Z4D8 -->
    <ShipArea deck="Z4D8" left="110" top="122" end="350" endv="150" />
    <!--Z3D8-->
    <ShipArea deck="Z1D3" left="350" top="122" end="747" endv="150" />
    <!--Z2D8-->
    <ShipArea deck="Z1D3" left="747" top="122" end="1113" endv="150" />
    <!--Z1D8-->
    <ShipArea deck="Z1D3" left="1114" top="122" end="1550" endv="150" />

    <!--Z4D9-->
    <ShipArea deck="Z4D9" left="174" top="93" end="350" endv="121" />
    <!--Z3D9 -->
    <ShipArea deck="9BG2" left="351" top="94" end="746" endv="121" />
    <!--Z2D9 -->
    <ShipArea deck="Z1D3" left="746" top="94" end="1113" endv="121" />
    <!--Z1D9 -->
    <ShipArea deck="Z1D3" left="1113" top="94" end="1539" endv="121" />

    <!--Bridge -->
    <!-- <ShipArea deck="Z1D3" left="1327" top="71" end="1500" endv="93" /> -->
  </div>
</template>

<script>
import ShipArea from "./ShipArea.vue";

export default {
  components: {
    ShipArea,
  },

  data() {
    return {
      suggestion: null,
    };
  },
  created() {
    // var axiosAuth = this.$store.getters.authenticatedAxios;
    // this.updateSRAP(axiosAuth);
  },
  methods: {
    // updateSRAP: function (axiosAuth) {
    // },
  },
};
</script>

<style lang="scss">
.ship-diagram {
  position: relative;
}

.ship-diagram img {
  display: block;
  width: 100%;
}

.area.danger {
  background-color: rgba(255, 0, 0, 0.5);
}

.area.warning {
  background-color: rgba(255, 111, 0, 0.507);
}

.area.good {
  background-color: rgba(255, 227, 0, 0.26);
}

// #area-1 {
//     position: absolute;
//     top: calcTop(192);
//     left: 24.3%;
//     width:calcWidth(628, 1104);
//     height: 10%;
// }
</style>