<template>
  <BSModal id="passPerGeofenceModal" title="Passengers Per Geofence">
    <template v-if="data !== null">
      <GeofenceSelector :geofenceData="data" />
    </template>
    <template v-else>
      <p>Loading...</p>
    </template>
  </BSModal>
  <button
    class="btn btn-primary btn-sm mr-2"
    data-toggle="modal"
    data-target="#passPerGeofenceModal"
  >
    Passengers Per Geofence
  </button>
</template>

<script>
import GeofenceSelector from "./GeofenceSelector.vue";
import BSModal from "../misc/bsmodal.vue";

export default {
  components: {
    GeofenceSelector,
    BSModal
  },
  data() {
    return {
      data: null
    }
  },
  created: function() {
    let axiosAuth = this.$store.getters.authenticatedAxios;
    this.getData(axiosAuth);
  },
  methods: {
    getData: async function(axiosAuth) {
      let promise = axiosAuth.get("/pameas/passengers_on_geofences/").then((response) => {
        this.data = response.data;
      });
      await Promise.allSettled([
        promise
      ]);
      setTimeout(() => this.getData(axiosAuth), 1000);
    }
  }
}
</script>

<style>

</style>