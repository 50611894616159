<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    :aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="modalLabel" v-html="title"></h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="executeClose()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer-buttons"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- <style lang="scss">
    .modal-body {
        position: relative;
        overflow-y: auto;
        max-height: 400px;
        padding: 15px;
    }
</style> -->

<script>
export default {
  props: ["id", "title", "onClose"],
  computed: {
    modalLabel: function () {
      return `${this.id}Label`;
    },
  },
  methods: {
    executeClose: function () {
      if (this.onClose) {
        this.onClose()
      }
    }
  }
};
</script>