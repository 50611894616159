<template>
    <div class="container-fluid">
        <template v-if="$store.state.incident.evacuation_level >= 2">
            <div class="row justify-content-center">
                <div class="col-8">
                    <iframe src="http://palaemon-sc.uah.es/pimm" id="camera-feed"></iframe>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row justify-content-center">
                <div class="col-8">
                    <span>Camera System Offline: No incident in progress.</span>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    /// TODO ADD CAMERA OFFLINE WHEN OL < 1
    name: "SmartCameraFeed"
}
</script>