<template>
  <div class="row">
    <div class="col-2" id="geofenceSelector">
      <div v-for="g in geofenceData" :key="g.geofence" @click="selectedGeofence = g.geofence">
        <span class="geofence-title">{{ g.geofence_label }}</span>
        <button class="btn btn-sm btn-info" @click="notifyPassengersOnGeofence(g.geofence)">Notify</button>
      </div>
    </div>
    <div class="col-10">
      <table v-if="selectedGeofence !== null" class="table">
        <thead>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Messaging ID</th>
        </thead>
        <tbody>
          <tr v-for="p in selectedGeofenceData.passengers" :key="p">
            <td>{{ p.first_name }}</td>
            <td>{{ p.last_name }}</td>
            <td>{{ p.messaging_id }}</td>
          </tr>
        </tbody>
      </table>
      <p v-else>Please select a geofence from the left</p>
    </div>
  </div>
</template>

<script>
import { showNotification } from "@/utils.js"; 

export default {
  props: ["geofenceData"],
  data() {
    return {selectedGeofence: null}
  },
  computed: {
    selectedGeofenceData: function () {
      return this.geofenceData.find((gf) => gf.geofence === this.selectedGeofence)
    }
  },
  methods: {
    notifyPassengersOnGeofence(geofence) {
      let axiosAuth = this.$store.getters.authenticatedAxios;
      axiosAuth.post("/pameas/passengers_on_geofences/", {
        geofence
      // eslint-disable-next-line no-unused-vars
      }).then((resp) => {
        showNotification("All the passengers within the geofence were notified.");
      })
    }
  }
}
</script>

<style>

</style>