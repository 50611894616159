<template>
  <BSModal id="musteredPassengersModal" title="Mustered Passengers">
    <div style="overflow: auto; height: 400px">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Muster Station</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(geofence, id) in musteredPassengers" :key="id">
            <td>{{ geofence.geofence }}</td>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Surname</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Age</th>
                  <th scope="col">Embarkation Port</th>
                  <th scope="col">Disembarkation Port</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(person, id1) in musteredPassengers[id].persons"
                  :key="id1"
                >
                  <td>{{ person.name }}</td>
                  <td>{{ person.surname }}</td>
                  <td>{{ person.gender }}</td>
                  <td>{{ person.age }}</td>
                  <td>{{ person.embarkationPort }}</td>
                  <td>{{ person.disembarkation_port }}</td>
                </tr>
              </tbody>
            </table>
          </tr>
        </tbody>
      </table>
    </div>
    <template v-slot:footer-buttons>
      <button type="button" class="btn btn-secondary" data-dismiss="modal">
        Close
      </button>
    </template>
  </BSModal>
  <button
    data-toggle="modal"
    data-target="#musteredPassengersModal"
    class="btn btn-info"
    v-if="musteredPassengers != null"
  >
    Mustered Passengers
  </button>
  <button disabled class="btn btn-info" v-else>Mustered Passengers</button>
</template>

<script>
import BSModal from "@/components/misc/bsmodal.vue";

export default {
  components: {
    BSModal,
  },
  data() {
    return {
      musteredPassengers: null,
    };
  },
  created: function () {
    const axios = this.$store.getters.authenticatedAxios;
    axios.get("pameas/mustered_passengers").then((response) => {
      this.musteredPassengers = response.data;
    });
  },
};
</script>