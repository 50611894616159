import { createApp } from 'vue';
import HighchartsVue from 'highcharts-vue';

// Styling
import 'jquery/dist/jquery';
import 'popper.js/dist/popper';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'

import PIMM from './PIMM.vue'
import router from './router'
import Suggestion from "@/components/dss/Suggestion";


import './css/main.scss';
import store from './store'

createApp(PIMM).use(store).use(router).use(HighchartsVue).component("Suggestion", Suggestion).mount('#app')
