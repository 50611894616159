<template>
  <div class="col-12 pimm-modal">
    <div :class="getClasses">
      <!-- <h2>Situation Assessment Recommendation:</h2> -->
      <div class="d-flex flex-row justify-content-between"> 
      <p :style="soundGAClasses">Sound GA: <b>{{ soundGA }}</b></p>
      <p :style="abandonClasses">Abandon: <b>{{ abandon }}</b></p>
      </div>
    </div>
  </div>
</template>

<style scoped>
p {
    font-size: 1.1em;
    margin-bottom: 0;
}
</style>

<script>
export default {
    computed: {
        soundGA() { return this.$store.getters.getSituationAssessment["soundGA"]; },
        abandon() { return this.$store.getters.getSituationAssessment["abandon"]; },
        abandonClasses() { 
            return {
                "abandon": {"color": "red"},
                "stay check": {"color": "green"} 
            }[this.abandon.toLowerCase()];
        },
        soundGAClasses() { 
            return {
                "sound ga": {"color": "red"},
                "no sound ga": {"color": "green"} 
            }[this.soundGA.toLowerCase()];
        },
    }
};
</script>

<style>
</style>