<template >
  <div v-bind:class="getClasses" v-if="getVal.length != 0">
    <li>{{ this.label }}: {{ getVal }}</li>
  </div>
</template>

<script>
export default {
  props: ["label", "value"],
  computed: {
    getVal: function () {
      return eval(this.value);
    },
    getClasses: function () {
      // If it's a number, return neutral as we can't determine the risk.
      if (!isNaN(this.getVal)) {
        return ["neutral_risk"];
      }

      const lowInstances = [
        "effective",
        "low",
        "contained",
        "not compromised",
        "sufficient",
        "safe",
        "fully operational",
        "no sound ga",
      ];
      const moderateInstances = ["Moderate", "Degraded", "Medium"].map((s) =>
        s.toLowerCase()
      );
      try {
        return {
          success_risk:
            lowInstances.includes(this.getVal.toLowerCase()) ||
            this.getVal != null,
          warning_risk: moderateInstances.includes(this.getVal.toLowerCase()),
          danger_risk:
            !lowInstances.includes(this.getVal.toLowerCase()) &&
            !moderateInstances.includes(this.getVal.toLowerCase()),
        };
      } catch (e) {
        console.error(this.value);
      }
      return {};
    },
  },
};
</script>