import axios from "axios";
import User from "../../models/User";
import router from "../router/index";

const BACKEND_ADDR = process.env.NODE_ENV == "production" ?
 "https://pimm.konnekt-able.com:8443" : "http://localhost:8000";
// const BACKEND_ADDR = "http://localhost:8000";
var TOKEN_ADDR = "/token/";
var REFRESH_ADDR = "/refresh/";

var LS_USERNAME = "USER";
var LS_ACCESS = "JWT_ACCESS";
var LS_REFRESH = "JWT_REFRESH";

var interceptors = {
    success_interceptor: (response) => {
        return response;
    },
    error_interceptor: (error) => {
        if (error.response.status !== 401) {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }

        if (error.config.url == "/refresh/"){
            // TODO Clear the token from storage
            return new Promise((resolve, reject) => {
                router.push("/login");
                reject(error);
            });
        }

        TokenManager.refreshToken().then((access) => {
            const config = error.config;
            config.headers["Authorization"] = `Bearer ${access}`;

            this.axios_auth.config = config;

            return new Promise((resolve, reject) => {
                axios.request(config).then((response) => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
            })
        })
    }
}

var authMethods = {
    tryLogin(username, password) {
        return new Promise((resolve, reject) => {
            axios.post(`${BACKEND_ADDR}${TOKEN_ADDR}`,
                {
                    username: username,
                    password: password
                }
            )
            .then(function (response){
                // self.setAxiosAuth(username, response.data.access, response.data.reject);
                TokenManager.storeTokens(response.data.access, response.data.refresh);
                resolve({ 
                    username: username, 
                    access: response.data.access,
                    refresh: response.data.refresh
                });
            })
            .catch(function (reason) {
                reject(reason);
            })
        });
    }
}

export {authMethods, interceptors, BACKEND_ADDR};

export class TokenManager {

    static refreshToken() {
        return new Promise((resolve, reject) => {
            axios.post(`${BACKEND_ADDR}${REFRESH_ADDR}`, 
                {
                    refresh: this.getRefreshToken()
                })
            .then((response) => {
                localStorage.setItem(LS_ACCESS, response.data.access);
                resolve(response.data.access);
            })
            .catch((error) => {
                reject(error);
            })
        });
    }

    static getRefreshToken() {
        return localStorage.getItem(LS_REFRESH);
    }

    static getAccessToken() {
        return localStorage.getItem(LS_ACCESS);
    }

    static storeTokens(access, refresh){
        localStorage.setItem(LS_ACCESS, access);
        localStorage.setItem(LS_REFRESH, refresh);
    }

    static getUsername() {
        return localStorage.getItem(LS_USERNAME);
    }

    static retrieveLogin(){
        var access = this.getAccessToken();
        if (access === null) {
            return null;
        }

        var refresh = this.getRefreshToken();
        var username = this.getUsername();

        return new User(username, access, refresh);
    }
}

// class Auth {
//     constructor() {
//         this.logged_in = null;
//         this.axios_auth = null;
//         ;

//         debugger;
//         var retrievedUser = TokenManager.retrieveLogin();
//         if (retrievedUser !== null) {
//             this.axios_auth = this.setAxiosAuth(
//                 retrievedUser.username, 
//                 retrievedUser.access_token,
//                 retrievedUser.refresh_token 
//                 );
//         }
//     }

//     setAxiosAuth(username, access, refresh) {
//         debugger;
//         this.logged_in = new User(
//             username,
//             access,
//             refresh,
//             );
//         this.axios_auth = axios.create({
//             baseUrl: BACKEND_ADDR,
//             headers: {
//                 "Authorization": "Bearer: " + access,
//             }
//         });
//         this.axios_auth.interceptors.response.use(this.interceptors.success_interceptor, this.interceptors.error_interceptor);
//     }

    

//     getAxios() {
//         return this.axios_auth;
//     }

//     isLoggedIn() { return this.logged_in !== null; }


// }