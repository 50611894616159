<template>
  <div class="muster-stations col-3">
    <div class="container">
      <h5 class="item">Muster station {{ station_name }}</h5>
      <span class="badge badge-danger item2" v-if="!value.open">Closed</span>
      <span
        v-else-if="value.passengersMissing == 0 && value.passengersAssigned > 0"
        class="badge badge-success item2"
        >Mustered</span
      >
      <span v-else-if="value.passengersMissing == 0"></span>
      <span class="badge badge-secondary item2" v-else>In Progress</span>
    </div>
    <br />
    <div>
      <p class="info">
        Total: <b>{{ value.passengersAssigned }}</b>
      </p>
      <br />
      <p class="info">
        Passengers currently in MS {{ station_name }} :
        <b>{{ value.passengersMustered }}</b>
      </p>
      <br />
      <p class="info-last">
        Passengers not in MS {{ station_name }}:
        <b>{{ value.passengersMissing }}</b>
        <button
          v-if="value.open"
          @click="onCloseMS(station_name)"
          type="button"
          class="btn btn-outline-danger btn-sm item3"
        >
          Close
        </button>
      </p>
    </div>
  </div>
</template>

<style lang="scss">
.muster-stations {
  border: 1px solid grayscale($color: #e8e8e8);
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 10px;
  background: white;
}

.container {
  flex-direction: row;
}

.center {
  margin: 10px;
  width: 80%;
  padding: 5px;
}

.container {
  display: inline-flex;
  background: #fff;
  flex-direction: row; /* change this to row instead of 'column' */
}

.item {
  margin: 12px;
  width: 100%; /* I added */
}

.item2 {
  width: 35%;
  margin: 12px;
  height: 10%;
  text-align: center;
}

.item3 {
  width: 25%;
  text-align: center;
  float: right;
}

.info {
  margin: -5px;
}

.info-last {
  margin-top: -5px;
  margin-left: -5px;
  margin-bottom: 0px;
}
</style>


<script>
import { musterStationGeofences } from "@/musterStationData.js";
import { closeGeofence } from "@/utils.js";
export default {
  props: ["station_name", "value"],
  methods: {
    onCloseMS: function () {
      var axiosAuth = this.$store.getters.authenticatedAxios;
      closeGeofence(axiosAuth, musterStationGeofences[this.station_name]);
    }
  },
};
</script>