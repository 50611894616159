<template>
  <div class="area" :class="type" :style="styles" />
</template>

<script>
export default {
  props: ["top", "left", "end", "endv", "deck"],
  methods: {
    calcTop: function () {
      return (this.top * 100) / 382;
    },
    calcLeft: function () {
      return (this.left * 100) / 1854;
    },
    calcWidth: function () {
      return ((this.end - this.left) * 100) / 1854;
    },
    calcHeight: function () {
      return ((this.endv - this.top) * 100) / 382;
    },
  },
  computed: {
    styles: function () {
      return {
        position: "absolute",
        top: `${this.calcTop()}%`,
        left: `${this.calcLeft()}%`,
        width: `${this.calcWidth()}%`,
        height: `${this.calcHeight()}%`,
      };
    },
    type: function () {
      let rod = this.$store.state.incident.srap.risk_of_delay[this.deck];
      switch (rod) {
        case "High":
          return "danger";
        case "Moderate":
          return "warning";
        case "Low":
          return "good";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss">
</style>