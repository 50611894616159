<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <h1>PoC Time Control:</h1>
        </div>
        <div class="row justify-content-center">
            <input type="checkbox" id="poc_control_checkbox" v-model="pocEnabled">
            <label for="poc_control_checkbox">PoC time control enabled</label>
        </div>
        <template v-if="pocEnabled">
            <div class="row justify-content-center">
                <select v-model="selectedTimelineSelect">
                    <option value="0">Grounding</option>
                    <option value="1">Fire</option>
                </select>
            </div>
            <div class="row justify-content-center my-3">
                <div class="col-10 timeline-button-container">
                    <template v-for="ts in timelines[timeline_chosen].timestamps" :key="ts">
                        <TimelineButton :time="ts"></TimelineButton>
                    </template>
                </div>
            </div>
            <div class="row justify-content-center my-3">
                <h1>The current time is: {{ $store.state.incident.poc_time }}</h1>
            </div>
            <div class="row justify-content-center my-3">
                <h1>The currently selected incident is: {{ selectedTimeline.name }}</h1>
            </div>
            <div class="row justify-content-center my-3">
                <div class="col-10">
                    <img :src="selectedTimeline.picture" class="d-block mx-auto" style="width: 1600px">
                </div>
            </div>
        </template>
        
    </div>
</template>

<script>
import TimelineButton from '@/components/poc-timeline/timeline-button.vue';

export default {
    name: "PoCTimeline",
    components: {
        TimelineButton: TimelineButton
    },
    created: function() {
        
    },
    watch: {
    },
    computed: {
        selectedTimeline() {
            var active_ts = this.$store.state.incident.poc_time;
            var tl_found = null;
            this.timelines.some((tl) => {
                if (tl.timestamps.includes(active_ts)){
                    tl_found = tl;
                    return true;
                }
            })
            return tl_found;
        },
        selectedTimelineSelect: {
            get() {
                var active_ts = this.$store.state.incident.poc_time;
                var ind = null;
                this.timelines.some((tl, index) => {
                    if (tl.timestamps.includes(active_ts)){
                        ind = index;
                        return true;
                    }
                })
                return ind;
            },
            set(ind) {
                let axios = this.$store.getters.authenticatedAxios;
                axios.put("/poc_time/", {time: this.timelines[ind].timestamps[0]});
                this.timeline_chosen = Number.parseInt(ind);
            }
        },
        pocEnabled: {
            get() {
                return this.$store.state.incident.poc_control_enabled;
            },
            set(checked) {
                let axios = this.$store.getters.authenticatedAxios;
            
                if (checked) {
                    // PoC Time control has been enabled.
                    // Produce the first timestamp of the first timeline.
                    axios.put("/poc_time/", {
                        time: this.timelines[0].timestamps[0],
                        enabled: true
                    });
                } else {
                    axios.put("/poc_time/", {
                        time: this.timelines[0].timestamps[0],
                        enabled: false
                    });
                }
                this.timeline_chosen = Number.parseInt(0);
            }
        }
    },
    data: function() {
        return {
            // Timelines to be used in the PoC.
            timelines: [
                {
                    name: "Grounding",
                    timestamps: [
                        "2012-01-13T23:00:00Z",
                        "2012-01-13T23:03:00Z",
                        "2012-01-13T23:45:00Z",
                        "2012-01-13T23:50:00Z",
                        "2012-01-14T00:00:00Z",
                        "2012-01-14T00:30:00Z",
                        "2012-01-14T00:35:00Z",
                        "2012-01-14T00:45:00Z",
                        "2012-01-14T01:00:00Z",
                    ],
                    picture: require("@/assets/grounding_timeline.png")
                },
                {
                    name: "Fire",
                    timestamps: [
                        "2014-12-28T03:00:00Z",
                        "2014-12-28T03:10:00Z",
                        "2014-12-28T03:20:00Z",
                        "2014-12-28T03:23:00Z",
                        "2014-12-28T03:25:00Z",
                        "2014-12-28T03:55:00Z",
                        "2014-12-28T04:00:00Z",
                        "2014-12-28T04:20:00Z",
                    ],
                    picture: require("@/assets/fire_timeline.png")
                },
            ],
            timeline_chosen: 0,
        }
    },
    method: {
    }
}
</script>