<template>
  <div class="container-fluid">
    <div class="row justify-content-center p-md-3">
      <div class="col-6">
        <div class="row justify-content-left">
          <div class="ship-stability">
            <h2>Ship Stability Toolkit</h2>
          </div>
          <div class="card panel-default col-12">
            <div class="card-body" ship>
              <SST></SST>
            </div>
          </div>
        </div>
        <div class="row justify-content-left">
          <div class="ship-stability">
            <h2>Situation Assessment Recommendation</h2>
          </div>
          <div class="card panel-default col-12">
            <div class="card-body" ship>
              <SituationAssessment />
            </div>
          </div>
        </div>
        <div class="row justify-content-left">
          <div class="dss-container">
            <h2>Desicion Support System</h2>
          </div>
          <div class="card panel-default col-12">
            <div class="card-body">
              <DSSNew></DSSNew>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div id="wft_div">
          <div class="dss-container">
            <h2>Weather Forecast Toolkit</h2>
          </div>
          <div class="card panel-default">
            <div class="card-body">
              <WFT></WFT>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12">
        <EvacuationManager></EvacuationManager>
      </div>
    </div>
  </div>
</template>

<script>
import WFT from "../components/WFT";
// import DSS from '../components/DSS';
import DSSNew from "../components/DSSNew";
import EvacuationManager from "../components/Evacuation-Manager.vue";
import SST from "../components/SST.vue";
import SituationAssessment from "../components/dss/SituationAssessment.vue";
export default {
  name: "DSSWFTScreen",
  components: {
    DSSNew,
    WFT: WFT,
    EvacuationManager: EvacuationManager,
    SST: SST,
    SituationAssessment
  },
  created() {
    if (!this.$store.state.incident.dss_suggestions) {
      let axiosAuth = this.$store.getters.authenticatedAxios;
      this.updateState(axiosAuth);
    }
  },
  methods: {
    updateState: function (axiosAuth) {
      axiosAuth.get("dss-suggestions-ls/").then((response) => {
        this.$store.commit(
          "setDSSSuggestions",
          response.data.hits.hits[0]._source.defaultSuggestion
        );
      });
    },
  },
};
</script>